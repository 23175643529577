import React from 'react';
import { Text, Image, Box, Container } from '@chakra-ui/react';
import Seo from '../../../components/sections/seo';

import colTestedTough from '../../../components/ui/columbia-tested-tough.jpg';
import colOffice from '../../../components/ui/columbia-office.jpg';
import colWhatWeBelieveImg from '../../../components/ui/ourbrands/columbia/col_careers.jpg';
import colCtaBkgImg from '../../../components/ui/ourbrands/columbia/col_cta_bkg.jpg';

import TextWithRightMedia from '../../../components/sections/textwithrightmedia';
import TextWithLeftMedia from '../../../components/sections/textwithleftmedia';
import CtaShort from '../../../components/sections/ctashort';

export default function ColumbiaTabPanel() {
  return (
    <Box>
      <Seo
          title="Careers at Columbia"
          description="Find your next career at Columbia."
          //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />
      <Container maxW='7xl' my={14} className='flow'>
      <Box 
          as={'section'}
          textAlign={'center'}
        >
          <Box
            className='mw-prose flow'
            mx={'auto'}
          >
            <h1>
              Come Play In Our Backyard
            </h1>
          </Box>
        </Box>
        <TextWithRightMedia
          title={'Who we are'}
          media={<Image src={colTestedTough} />}
          content={
            <>
              <Text>
                Columbia is grounded in the outdoors. Looking back at our storied history, we believe in toughness—of products and people. And as a global apparel, equipment, and footwear company with over 85 years of making gear to get people outside we’ve learned a thing or two. And keep on learning. We obsess each product until we get it right, inspired by the words of our Founder, Gert Boyle: “It’s perfect, now make it better.” And we show up for work each day to do just that.
              </Text>
            </>}
        />
        <TextWithLeftMedia
          title={'What we believe'}
          media={<Image src={colWhatWeBelieveImg} />}
          content={
            <>
              <Text>
                We unlock the outdoors for everyone, including you. And we believe in unlocking the potential of our employees, too. By helping us push the limits of innovation you’ll push your own limits as well. And though we don’t take ourselves too seriously, we are incredibly serious about crafting the best possible gear. We keep on designing, testing, and improving. As product pioneers, we’ve even tested our technology on the Moon!
              </Text>
            </>}
        />
         <TextWithRightMedia
          title={'Why we work here'}
          media={<Image src={colOffice} />}
          content={
            <>
              <Text>
                If you value a fun and collaborative environment infused with a spirit of equality and inclusion, Columbia might be the place for you. Office chat is not just about tasks at hand but about the best local trails and biggest fish ever, proving that work-life balance matters here. Nature is our playground, and we use that first-hand knowledge to keep you warm, cool, dry, and protected no matter what. If you like the idea of unlocking the outdoors for everyone, come join us today.
              </Text>
            </>}
        />
         <CtaShort
          button_text={'Job Opportunities'}
          button_url={'https://columbiasportswearcompany.wd5.myworkdayjobs.com/Columbia_Career_Site?source_id=csccom'}
          backgroundImage={colCtaBkgImg}
        />
      </Container>
    </Box>
  );
}
