import AAFA from './AAFA.jpg';
import Afirm from './afirm_logo.png'
import BSR from './BSR.jpg';
import FDRA from './FDRA.jpg';
import OutdoorIndustry from './OutdoorIndustry.jpg';
import FLA from './FairLaborAssociation.jpg';
import SAC from './SustainableApparelCoalition.jpg';
import SLConvergence from './SLConvergence.jpg';
import TextileExchange from './TextileExchange.png';

const OrganizationImages = [
    { src: OutdoorIndustry, href: "https://outdoorindustry.org/sustainable-business/" },
    { src: BSR, href: "https://herproject.org/"},
    { src: SAC, href: "https://apparelcoalition.org/"},
    { src: TextileExchange, href: "https://textileexchange.org/"},
    { src: SLConvergence, href: "https://slconvergence.org/"},
    { src: AAFA, href: "https://www.aafaglobal.org/AAFA/Priorities/Supply_Chain/AAFA/Priority/Supply_Chain.aspx?hkey=10497d02-a393-4112-95cf-4e1d3f680c01"},
    { src: FDRA, href: "https://fdra.org/"},
    { src: Afirm, href: "https://afirm-group.com/"},
    { src: FLA, href: "https://www.fairlabor.org/"}
];

export default OrganizationImages;