import React from 'react';
import { Button, Image, LinkBox, LinkOverlay, Box, Text, Icon, Container, Heading, UnorderedList, ListItem, SimpleGrid, Stack, Flex } from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Seo from '../../../components/sections/seo';

import QuoteWithLeftMedia from '../../../components/sections/quotewithleftmedia';
import QuoteWithRightMedia from '../../../components/sections/quotewithrightmedia';
import HeroWithRightMedia from '../../../components/sections/herowithrightmedia';
import GridCardNoLink from '../../../components/sections/gridcardnolink'

import sandrineChristin from '../../../components/ui/regionalcareers/europe/RoadtoTrail-1.jpg'
import matthieuSchegg from '../../../components/ui/regionalcareers/europe/Mattheiu-Schegg-1.jpg'
import franceCard from '../../../components/ui/regionalcareers/europe/FRANCE1-1.jpg'
import ukCard from '../../../components/ui/regionalcareers/europe/united-kingdom.jpg'
import spainCard from '../../../components/ui/regionalcareers/europe/SPAIN1.jpg'
import switzerlandCard from '../../../components/ui/regionalcareers/europe/SWITZERLAND1.jpg'
import germanyCard from '../../../components/ui/regionalcareers/europe/GERMANY1.jpg'
import italyCard from '../../../components/ui/regionalcareers/europe/ITALY1.jpg'
import teamEvents from '../../../components/ui/regionalcareers/europe/Team-Events-resize467x316.png'
import teamActivities from '../../../components/ui/regionalcareers/europe/Team-Activities-resize735x472-crop735x445.png'
import charlesWillieme from '../../../components/ui/regionalcareers/europe/Charles-WILLIEME-Resized.jpg'
import oleksandrVitenko from '../../../components/ui/regionalcareers/europe/Oleksandr-Vitenko-Headshot-Resized.jpg'
import europeCareers from '../../../components/ui/regionalcareers/europe/europe_careers.jpg'

export default function EuropeTabPanel() {
  return (
    <Box>
        <Seo
            title="Careers in Europe"
            description="Find your next job in our European locations."
            //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />
        <Container maxW={'6xl'} my={9}>
            <Box className='flow'>
                <Heading as={'h1'}>Europe Region</Heading>
                <Text>
                    Our European Headquarters is located centrally and at the foot of the Alps in Geneva, Switzerland, inspiring our employees with its immense beauty and easy access to outdoor spaces and activities.
                </Text>
                <Text>
                    The Europe Region is home to our Europe regional office in Switzerland, retail stores located in 19 countries and a global distribution center in France.
                </Text>
                <QuoteWithLeftMedia media={<Image borderRadius={10} src={sandrineChristin} />} quote={`“I run because I like the impression of being free.”`} speaker="– Sandrine Christin, Columbia Employee" />
                <Stack my={10}>
                    <Flex 
                        minH={'375px'}
                        justify="center"
                        align="center"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${europeCareers})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        borderRadius={10}
                        flexDir={'column'}
                    >
                        <Text
                            whiteSpace="pre-line"
                            align="center"
                            color="white"
                            fontWeight="bold"
                            fontFamily="GerTT"
                            fontSize="4xl"
                            opacity={1.0}
                            textTransform={'uppercase'}
                            sx={{'--flow-lh': 'lineHeights.10'}}
                            py={3}>Check Out Jobs in Europe
                        </Text>
                        <LinkBox as={'a'}>
                            <LinkOverlay
                                href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
                                isExternal
                                >
                                    <Button
                                        size="md"
                                        p={6}
                                        bg="black"
                                        color="white"
                                        borderRadius={8}
                                        mt={8}
                                        textTransform={'uppercase'}
                                        >
                                        Join the Team
                                        <Icon
                                            color="white"
                                            w={5}
                                            h={5}
                                            as={ExternalLinkIcon}
                                            pl="4px"
                                            ml={1}
                                        >
                                        </Icon>
                                    </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </Flex>
                </Stack>
                <Heading as={'h3'} textAlign={'center'}>
                    Our Europe Team
                </Heading>
                <Text>
                    With more than 500 employees in Europe, and directly operating in 14 countries, Columbia Sportswear is one of the key players in the European outdoor market. In Europe, our brand portfolio includes: Columbia Sportswear, Mountain Hardwear and SOREL. Across geographies, our combined mission remains the same:
                </Text>
                <Text as={'blockQuote'} 
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    fontWeight={600}
                    fontSize={{base:'2xl', md: '3xl'}}
                    my={10}
                    fontFamily={'GerTT'}>
                    We Connect Active People with their Passions
                </Text>
                <Text>
                    Columbia Sportswear is hiring talented people across Europe at:
                </Text>
                <UnorderedList>
                    <ListItem>Our corporate offices (Geneva, Switzerland, and Strasbourg, France)</ListItem>
                    <ListItem>Distribution Center (Cambrai, France)</ListItem>
                    <ListItem>Retail Stores, showrooms and Regional offices across the region</ListItem>
                </UnorderedList>
                <HeroWithRightMedia
                    media={<ReactPlayer url="https://www.youtube.com/embed/vzOwQ0_tqgk?autoplay=0&rel=0&enablejsapi=1&wmode=opaque" />}
                    title="TESTED TOUGH IN THE FRENCH ALPS"
                    subTitle="Meet Columbia employee Christelle (PR and Events Manager, Europe) as she tests gear in the beautiful French Alps."
                    cta={<LinkBox as="a">
                        <LinkOverlay href="https://www.youtube.com/watch?v=vzOwQ0_tqgk" isExternal>
                        <Button size="lg" px={6} bg="black" color="white" borderRadius={10} textTransform={'uppercase'}>Watch Video<Icon color="white" w={5} h={5} as={ExternalLinkIcon} pl="4px"/></Button>
                        </LinkOverlay>
                    </LinkBox>}
                />
                <Heading as={'h3'} textAlign={'center'}>
                    Our Vision
                </Heading>
                <QuoteWithLeftMedia media={<Image borderRadius={10} src={matthieuSchegg} />} quote={`“It is a really exciting time to work at Columbia in Europe. The market is changing faster than ever, distribution and communication are changing and consumer expectations are changing as well. The brands within our portfolio have been enjoying great momentum and the future perspectives are great. I am happy to lead a energetic, passionate international team consisting of 20 nationalities serving 19 countries and 23 languages!”`} speaker="– Matthieu Schegg, Europe General Manager" />
                <Heading as={'h3'} textAlign={'center'}>
                    Our Countries in Europe
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} my={12}>
                    <GridCardNoLink
                        title='France'
                        backgroundImage={franceCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='United Kingdom'
                        backgroundImage={ukCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Spain'
                        backgroundImage={spainCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Switzerland'
                        backgroundImage={switzerlandCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Germany'
                        backgroundImage={germanyCard}
                        href='#'
                        />
                    <GridCardNoLink
                        title='Italy'
                        backgroundImage={italyCard}
                        href='#'
                        />
                </SimpleGrid>
                <Heading as={'h3'} textAlign={'center'}>
                    Life in Europe
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Box as='figure'>
                        <Image
                            src={teamEvents}
                            alt='Team events in Europe'
                            objectFit={'cover'}
                            borderRadius={'10px'}
                            h={'300px'}
                            w={'540px'}
                        />
                        <Box as='figcaption'>
                            <Text
                                fontSize={"sm"}
                                mt={2}
                                >
                                    <Text as={'span'} fontWeight={600} textTransform={'uppercase'}>Team Events: </Text>
                                Our Geneva team enjoying a lunch break on the patio.
                            </Text>
                        </Box>
                    </Box>
                    <Box as='figure'>
                        <Image
                            src={teamActivities}
                            alt='Team activities in Europe'
                            objectFit={'cover'}
                            borderRadius={'10px'}
                            h={'300px'}
                            w={'540px'}
                        />
                        <Box as='figcaption'>
                            <Text
                                fontSize={"sm"}
                                mt={2}
                                >
                                    <Text as={'span'} fontWeight={600} textTransform={'uppercase'}>Team Activities: </Text>
                                Beach cleaning by our employees in Portugal, in 2018.
                            </Text>
                        </Box>
                    </Box>
                </SimpleGrid>
                <QuoteWithRightMedia media={<Image borderRadius={10} src={charlesWillieme} />} quote={`“I like being part of a project and Columbia allows me to do that. We have great products and a great work environment.”`} speaker="– Charles Willieme, Customer Rep, France" />
                <QuoteWithLeftMedia media={<Image borderRadius={10} src={oleksandrVitenko} />} quote={`“It is amazing to work for Columbia Sportswear Company. Around me I find active people with an open mind.”`} speaker="– Oleksandr Vitenko, Outlet Store Manager, Italy" />
            </Box>
        </Container>
    </Box>
  );
}
