import React from 'react';
import {
    Flex,
    useBreakpointValue,
    VStack,
    Text,
    Stack,
    Heading,
    Link,
    Container,
    UnorderedList,
    ListItem,
  } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import elevatingResponsibilityHeroImg from '../../../components/ui/corporateresponsibility/responsiblepractices/elevatingresponsibility/Responsible-Practices-Elevating-Responsibility-Img.jpg'

export default function ElevatingResponsibilty() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility - Elevating Responsibility"
                description="Promoting responsible manufacturing through the careful evaluation and selection of vendors."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Flex
                w={'full'}
                h={'60vh'}
                className='page-banner'
                backgroundImage={elevatingResponsibilityHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="elevatingResponsibilityHeroImg"
                title={`Elevating Responsibility`}
            >
                <VStack
                    w={'full'}
                    justify={'flex-end'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={6} px={4}>
                        <Heading
                            align="right"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            mb="10px"
                            id="responsiblepracticestext"
                            >
                                <Text as="span" 
                                    fontSize={useBreakpointValue({
                                        base: 'xl',
                                        md: '3xl',
                                    })}
                                    >
                                    Responsible Practices: <br />
                                </Text>
                            Elevating Responsibility, Integrity, and Compliance
                        </Heading>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW="6xl" my={9} className='flow'>
                <Text>
                    Promoting responsible manufacturing by selecting vendors that have demonstrated commitments to continuously improve their Social Responsibility, Environmental and Product Compliance metrics.
                </Text>
                <Heading as="h2">
                    Social Responsibility
                </Heading>  
                <Text>
                    Social Responsibility begins with partnering with suppliers who share our values of fair and ethical treatment of workers, safe working conditions, transparency and sustainable manufacturing practices. It also includes looking internally at our own practices to ensure our partners can meet our standards and requirements.
                </Text>
                <Heading as="h3" fontSize={'2xl'}>
                    Standards of Manufacturing Practices (SMP)
                </Heading>
                <Text>
                    Our <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_Manufacturing_Poster.pdf' isExternal>Standards of Manufacturing Practices (SMP)</Link> (also known as our Code of Conduct) outlines our expectations of our suppliers in 12 core areas listed below. These standards are based on <Link textDecoration={'underline'} href='http://www.ilo.org/global/about-the-ilo/lang--en/index.htm' isExternal>International Labor Organization (ILO)</Link> conventions and <Link textDecoration={'underline'} href='http://www.fairlabor.org/' isExternal>Fair Labor Association</Link> standards.
                </Text>
                <Text>
                    Each factory producing Columbia Sportswear Company products is required to post our standards on the production floor in the local language to ensure workers are aware of their rights. Factories are regularly monitored through unannounced audits against our SMP, in addition to local labor laws. Our SMP outlines standards in the following areas:
                </Text>
                <UnorderedList>
                    <ListItem>Forced Labor</ListItem>
                    <ListItem>Child Labor</ListItem>
                    <ListItem>Harrassment or Abuse</ListItem>
                    <ListItem>Nondiscrimination</ListItem>
                    <ListItem>Freedom of Association</ListItem>
                    <ListItem>Compensation</ListItem>
                    <ListItem>Employment Relationship</ListItem>
                    <ListItem>Hours of Work</ListItem>
                    <ListItem>Health and Saftety</ListItem>
                    <ListItem>Environment</ListItem>
                    <ListItem>Ethical Conduct</ListItem>
                    <ListItem>Documentation and Inspection</ListItem>
                </UnorderedList>
                <Heading as='h3' fontSize={'2xl'}>
                    Monitoring for Continuous Improvement
                </Heading>
                <Text>
                    Columbia Sportswear Company employs a team of Corporate Responsibility Specialists who conduct regular audits and assessments of our suppliers against our SMP. The majority of audits are conducted on an unannounced basis, allowing our Specialists to see the factory conditions as they are on a typical day. For higher performing factories, we are adopting the <Link textDecoration={'underline'} href='https://slconvergence.org/' isExternal>Social & Labor Converged Assessment Framework (SLCP)</Link> built collectively by the Apparel and Footwear industry, which is a self-assessment with verification model. We believe this approach will encourage “Supplier Ownership” of their internal human resources management, and also will allow for reduction of audit fatigue as the SLCP model also includes an assessment sharing platform.
                </Text>
                <Text>
                    Using the audit or assessment results, we work with the factories to develop a Corrective Action Plan to remediate issues identified. As we consider our suppliers as partners, our approach to remediation is continuous improvement, working together to improve working conditions.
                </Text>
                <Text>
                    To support this process, we maintain an internal Corporate Responsibility database that houses factory profile information, along with audit/assessment and remediation performance to record the data needed to track conditions and progress over time. We are committed to transparency and collaboration. Therefore, we disclose <Link href='/corporate-responsibility-group/responsible-practices/supply-chain' textDecoration={'underline'}>our factories</Link> that are directly contracted by Columbia, Mountain Hardwear and Sorel brands. We believe that this level of transparency helps foster collaboration, improves factory performance under brands’ codes of conduct, and affects positive change across the industry.
                </Text>
                <Heading as="h3" fontSize={'2xl'}>
                    SMP Scorecard
                </Heading>
                <Text>
                    To track our supplier SMP performance, we have developed an SMP Scorecard that allows us to evaluate and track performance based on the following criteria:
                </Text>
                <UnorderedList>
                    <ListItem>Audit Performance</ListItem>
                    <ListItem>Remediation Efforts</ListItem>
                    <ListItem>Management Systems</ListItem>
                    <ListItem>Transparency</ListItem>
                </UnorderedList>
                <Text>
                    We use the SMP score, which is updated monthly, to determine level of support needed by our suppliers. The support ranges from additional capability building for lower performing suppliers, to participation in special projects such as the HERproject for higher performing suppliers. The SMP score is also a key component of our overall supplier ratings which is used to inform seasonal sourcing decisions.
                </Text>
                <Heading as="h3" fontSize={'2xl'}>
                    Training and Capability Building
                </Heading>
                <Text>
                    Monitoring alone is not enough. We also support supplier efforts to promote sustainable solutions. Our training and capability building program encourages suppliers to further develop their skills:
                </Text>
                <UnorderedList>
                    <ListItem>Human Resources Management</ListItem>
                    <ListItem>Effective Worker-Management Dialogue</ListItem>
                    <ListItem>Production Efficiency</ListItem>
                    <ListItem>Environmental Management</ListItem>
                    <ListItem>Fire and Emergency Safety</ListItem>
                </UnorderedList>
                <Heading as="h2">
                    Environmental Sustainability
                </Heading>
                <Heading as="h3" fontSize={'2xl'}>
                    Higg Index in the Supply Chain
                </Heading>
                <Text>
                    Columbia Sportswear Company employs a team that is dedicated to assessing environmental impacts from manufacturing. Our objective is to measure, track and improve the energy, waste, water and chemical impacts associated with manufacturing our products. We use the <Link textDecoration={'underline'} href='https://apparelcoalition.org/higg-facility-tools/' isExternal>Higg Index Facility Environmental Module</Link> to assess supplier performance and identify overall strengths and weaknesses in our supply chain. In 2018, we completed our fifth round of Higg FEM supplier assessments with approximately 99% of our finished goods suppliers and 80% of our strategic raw material vendors completing the assessment. We have been using the Higg Index framework with our finished goods facilities since 2013 and have been seeing consistently positive improvements, such as increased energy measurement and target-setting at a supplier level.
                </Text>
                <Heading as="h2">
                    Product Compliance and Chemical Management
                </Heading>
                <Text>
                    We strive to make products that are safe and compliant from initial concept through to manufacturing and delivery to our consumers. Our Global Product Compliance team works closely with product creation teams and manufacturing partners to ensure and that our products meet requirements for the markets we sell in. That includes setting product standards and industry best practices that translate into a high level of product integrity across all our brands. We established a Restricted Substances List (RSL) based on global laws and industry standards, and have implemented a corresponding testing program that monitors compliance to those laws and standards. We provide extensive training on our RSL and testing standards for our global partners, including sourcing and manufacturing teams to build comprehensive knowledge around our RSL requirements. Our RSL and Product Compliance Manual can be found <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_English.pdf' isExternal>here</Link>.
                </Text>
                <Text>
                    We are committed to contributing to the development and adoption of leading industry standards. As part of our commitment, we joined <Link textDecoration={'underline'} href='https://www.bluesign.com/en' isExternal>bluesign</Link>® technologies as a bluesign® system partner in 2016. The bluesign® system provides an independent, systematic solution for sustainable production and sets standards through an input stream management system and material production facility certification. We use the bluesign® Restricted Substance List as the basis for our Restricted Substances List. The bluesign® system helps ensure textile manufacturing facilities and the textile products produced there meet stringent chemical requirements, and provides confidence to the consumer of a more sustainable product. In 2018, 50% of strategic material vendors were bluesign® certified, and we continue to incorporate more bluesign® materials into our products each year.
                </Text>
                <Heading as="h2">
                    Materials Traceability
                </Heading>
                <Text>
                    We believe it is important to know the origin of the materials we use for our products to ensure that the material does indeed come from its reported source, and that materials are sourced in accordance with our responsible sourcing guidelines. Our Materials Traceability program enables us to track the chain of custody from raw material origins to finished product.
                </Text>
                <UnorderedList>
                    <ListItem>
                        <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Conflict_Minerals.pdf' isExternal>Conflict Minerals</Link>
                        <UnorderedList>
                            <ListItem>
                                We strive to ensure that 3TG minerals used in our products are obtained from certified suppliers that verify and validate responsible sourcing throughout all regions within our supply chain.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Uzbekistan&Turkmenistan_Cotton_Statement.pdf' isExternal>Uzbekistan and Turkmenistan Cotton Policy</Link>
                        <UnorderedList>
                            <ListItem>
                                We require our suppliers to avoid using cotton sourced from Uzbekistan or Turkmenistan due to issues of forced labor.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Animal_Derived_Materials_Policy.pdf' isExternal>Animal-Derived Materials Policy</Link>
                        <UnorderedList>
                            <ListItem>
                                Columbia Sportswear is committed to welfare of animals as they relate to our products. We do not allow the use of real fur in our products. We expect any farm-raised animal-derived material source to comply with the Five Freedoms, the standard for animal welfare.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        <Link textDecoration={'underline'} href='https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Natural_Down&Feather.pdf' isExternal>Natural Down and Feather</Link>
                        <UnorderedList>
                            <ListItem>
                                Columbia Sportswear Company is strongly opposed to inhumane down sourcing practices, specifically any use of live-plucking techniques and/or force-feeding methods used to produce foie gras. To support this stance, we use only RDS-certified down in all our products globally.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                </UnorderedList>
                <Text>
                    These policies and standards can be found on our website under <Link textDecoration={'underline'} href='/corporate-responsibility/responsible-practices'>Position Statements</Link>.
                </Text>
            </Container>
        </HomeLayout>
    );
}