import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Heading, Text } from '@chakra-ui/react';

export default function DynamicTabs ({ controlBkgImage, naTopImage, naLeftImage, naRightImage, euTopImage, euLeftImage, euRightImage, asTopImage, asLeftImage, asRightImage }) {
  return (
    <Box>
        <Tabs 
            variant='unstyled'
            display={'grid'}
            gridTemplateColumns={{ base: '1fr', lg: '40% 1fr' }}
            width={'100%'}
            minH={'600px'}
            height={{ base: '100%', lg: '100vh' }}
        >
            <TabList
                display={'flex'}
                flexDir={'column'}
                flexBasis={'100%'}
                flex={1}
                justifyContent={'center'}
                align="center"
                bgImage={`linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url(${controlBkgImage})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                
            >
                <Box 
                    className='text-component flow'
                    padding={'var(--space-xl)'}
                    textAlign={'left'}
                    color={'white'}
                    sx={{'--flow-space': 'var(--space-m)'}}
                    maxW={{base: '100%', lg: '62ch'}}    
                >
                    <Heading
                        fontSize={'var(--step-3)'}
                        textTransform={'none'}
                    >
                        Explore Our World
                    </Heading>
                    <Text>
                        Portland, Oregon is where it all began and where a lot still happens. But since we started in 1938, we haven’t stopped exploring. With locations across over 30 countries, we have work to do – so, what are you waiting for?
                    </Text>
                    <Text>
                        Learn more about our locations here:
                    </Text>
                    <Box>                  
                        <Tab
                            width={'75%'}
                            justifyContent={'flex-start'}
                            _selected={{ backgroundColor: 'whiteAlpha.800', color: 'black', fontWeight: 600 }}
                        >
                            North America
                        </Tab>
                        <Tab
                            width={'75%'}
                            justifyContent={'flex-start'}
                            _selected={{ backgroundColor: 'whiteAlpha.800', color: 'black', fontWeight: 600 }}
                        >
                            Europe
                        </Tab>
                        <Tab
                            width={'75%'}
                            justifyContent={'flex-start'}
                            _selected={{ backgroundColor: 'whiteAlpha.800', color: 'black', fontWeight: 600 }}
                        >
                            Asia
                        </Tab>
                    </Box>
                </Box>
            </TabList>
            <TabPanels
                minH={'600px'}
            >
                <TabPanel
                    display={'grid'}
                    gridTemplateColumns={'repeat(2, 1fr)'}
                    gridTemplateRows={'repeat(2, 50%)'}
                    height={{ base: '600px', md: '100%'}}
                    width={'100%'}
                    padding={0}
                >
                    <Box
                        gridColumn={'1 / 3'}
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${naTopImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                5
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Corporate<br /> Offices
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${naLeftImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                175+
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Retail Stores
                            </Text>
                        </Box>

                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${naRightImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                3
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Distribution<br /> Centers
                            </Text>
                        </Box>
                    </Box>
                </TabPanel>

                <TabPanel
                    display={'grid'}
                    gridTemplateColumns={'repeat(2, 1fr)'}
                    gridTemplateRows={'repeat(2, 50%)'}
                    height={{ base: '600px', md: '100%'}}
                    width={'100%'}
                    padding={0}
                >
                    <Box
                        gridColumn={'1 / 3'}
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${euTopImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                2
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Corporate<br /> Offices
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${euLeftImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                50+
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Retail Stores
                            </Text>
                        </Box>

                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${euRightImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                1
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Distribution<br /> Center
                            </Text>
                        </Box>
                    </Box>
                </TabPanel>

                <TabPanel
                    display={'grid'}
                    gridTemplateColumns={'repeat(2, 1fr)'}
                    gridTemplateRows={'repeat(2, 50%)'}
                    height={{ base: '600px', md: '100%'}}
                    width={'100%'}
                    padding={0}
                >
                    <Box
                        gridColumn={'1 / 3'}
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${asTopImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                15
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Corporate<br /> Offices
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${asLeftImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                            <Text as={'span'}
                                fontSize={'var(--step-6)'}
                                lineHeight={0.9}
                            >
                                200+
                            </Text>
                            <Text as={'span'}
                                fontSize={'var(--step-1)'}
                                lineHeight={1.1}
                            >
                                Retail Stores
                            </Text>
                        </Box>

                    </Box>
                    <Box
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${asRightImage})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        pos={'relative'}
                    >
                        <Box
                            className='text-container'
                            color={'white'}
                            pos={'absolute'}
                            top={'50%'}
                            right={0}
                            display={'flex'}
                            flexDir={'column'}
                            px={'var(--space-xl)'}
                            fontFamily={'Gertt'}
                            textAlign={'right'}
                            >
                        </Box>
                    </Box>
                </TabPanel>
            </TabPanels>
        </Tabs>
      
    </Box>
  );
};
