import { Text, Image, Link, Grid, GridItem } from '@chakra-ui/react';

export default function Callout({ media, headline, description, link_url, link_text, bkg_color }) {

  return (
    <Grid
        gridTemplateColumns={{ base: '1fr', md: '30% 1fr'}}
        gap={3}
        direction={{ base: 'column', md: 'row' }}
        minH={'150px'}
        sx={{'--flow-space': 'var(--space-l)'}}
        backgroundColor={ bkg_color ? bkg_color : 'var(--chakra-colors-gray-100)' }
    > 
        <GridItem 
            className='co_media'
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
        >
            <Image 
                src={ media }
                objectFit={'cover'}
                minWidth={'100%'}
                minH={'100%'}
            />
        </GridItem>
        <GridItem 
            className='co_text'
            p={'var(--space-xs)'}
        >
            <h3>{ headline }</h3>
            <Text fontSize='var(--step--1)'>{ description }</Text>
            <Link
                href={ link_url }
                display={'block'}
                mt={'var(--space-xs)'}
                fontSize='var(--step--1)'
                textDecoration={'underline dotted'}
                textUnderlineOffset={'4px'}

            >
                { link_text }
            </Link>
        </GridItem>  
    </Grid>
  );
}
