import { Box, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import GridCard from '../gridcard'
import brandsCard from '../../ui/brands-card.jpg';
import strategyCard from '../../ui/strategy-card.jpg';
import leadershipCard from '../../ui/leadership-card.jpg';
import locationsCard from '../../ui/locations-card.jpg';
import corporateResponsibilityCard from '../../ui/corporate-responsibility-card.jpg';

import investorsCard from '../../ui/investors-card.jpg';


export default function SimpleThreeColumns() {
  return (
    <Box p={4}>
      <VStack justify="center" marginBottom="var(--space-xl)" textAlign={'center'}>
        <h2>A Different Kind of Adventure</h2>
        <Text fontSize={'md'}>We’ve been creating innovative outdoor gear and technologies since 1938.
          It’s a good start.</Text>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
        <GridCard
          title="Our Brands"
          backgroundImage={brandsCard}
          href="/careers/our-brands-group"
        />
        <GridCard
          title="Strategy, Mission & Values"
          backgroundImage={strategyCard}
          href="/our-company/strategy-mission-values"
        />
        <GridCard
          title="Leadership Team"
          backgroundImage={leadershipCard}
          href="/our-company/leadership"
        />
        <GridCard
          title="Regions & Locations"
          backgroundImage={locationsCard}
          href="/careers/regions-group/?tab-c40defabe206bb77568"
        />
        <GridCard
          title="Corporate Responsibility"
          backgroundImage={corporateResponsibilityCard}
          href="corporate-responsibility-group"
        />
        <GridCard
          title="Investor Relations"
          backgroundImage={investorsCard}
          href="https://investor.columbia.com/"
          isExternal = {true}
        />
      </SimpleGrid>
    </Box>
  );
}
