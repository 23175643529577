import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {

  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Flex,
  Stack,
  VStack,
  useBreakpointValue,
  Text
} from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout'
import CorporateTabPanel from './corporatetabpanel'
import ColumbiaCorporatePanel from './columbiacorporatepanel'
import SorelCorporatePanel from './sorelcorporatepanel'
import MountainHardwearCorporatePanel from './mhwcorporatepanel'
import PranaCorporatePanel from './prAnacorporatepanel'
 import leadershipHeroImg from '../../components/ui/ourcompany/leadership/Alternate-Leadership-Team-Image.png'


export default function OurLeadership() {
  const tabMap = useMemo(
    () =>
      new Map([
        [0, 'tab-e56b0ddf3c4dbb8b532'],
        [1, 'tab-83822cc34792877f69a'],
        [2, 'tab-a1deda7be67d63824cf'],
        [3, 'tab-cc8cafd62846e2bc59f'],
        [4, 'tab-acc79ea58ca3c1222ba'],
      ]),
    []
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = useCallback(
    index => {
      setSearchParams(tabMap.get(index), { replace: true });
      setTabIndex(index);
    },
    [tabMap, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.has(tabMap.get(0))) {
      handleTabsChange(0);
    } else if (searchParams.has(tabMap.get(1))) {
      handleTabsChange(1);
    } else if (searchParams.has(tabMap.get(2))) {
      handleTabsChange(2);
    } else if (searchParams.has(tabMap.get(3))) {
      handleTabsChange(3);
    } else if (searchParams.has(tabMap.get(4))) {
      handleTabsChange(4);
    }
    
  }, [tabMap, searchParams, handleTabsChange]);

  return (
    <HomeLayout align="left">
      <Flex
        w={'full'}
        h={'60vh'}
        className='page-banner'
        backgroundImage={leadershipHeroImg}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        id="strategyMissionValuesHeroImg"
        title={`READY FOR YOUR NEXT ADVENTURE?\nLET’S EXPLORE!`}
      >
        <VStack
          w={'full'}
          justify={'center'}
          align={'left'}
          px={useBreakpointValue({ base: 4, md: 8 })}
          bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
        >
          <Stack maxW={'2xl'} spacing={6}>
            <Text
              align="center"
              color={'white'}
              fontWeight={600}
              lineHeight={1.2}
              fontSize={useBreakpointValue({
                base: '2xl',
                md: '4xl',
                sm: '3xl',
              })}
              mb="10px"
              id="officersanddirectorstext"
            >
              OFFICERS & DIRECTORS
            </Text>
          </Stack>
        </VStack>
      </Flex>
      <Tabs
        isFitted
        w="full"
        isLazy
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList>
          <Tab>Corporate</Tab>
          <Tab>Columbia</Tab>
          <Tab>SOREL</Tab>
          <Tab>Mountain Hardwear</Tab>
          <Tab>prAna</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CorporateTabPanel />
          </TabPanel>
          <TabPanel>
            <ColumbiaCorporatePanel />
          </TabPanel>
          <TabPanel>
            <SorelCorporatePanel />
          </TabPanel>
          <TabPanel>
            <MountainHardwearCorporatePanel />
          </TabPanel>
          <TabPanel>
            <PranaCorporatePanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </HomeLayout>
  );
}
