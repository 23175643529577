import React from 'react';
import {
  Image,
  Box,
  Text,
  Container
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';

import BioWithRightMedia from '../../components/sections/biowithrightmedia';


import EVPColumbia from '../../components/ui/ourcompany/leadership/Joe-Boyle.jpeg'
export default function ColumbiaTabPanel() {
  return (
    <Box>
      <Container maxW='6xl' my={14} className='flow'>
      <Seo
        title="Leadership at Columbia"
        description="Executive leadership at Columbia."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
        <BioWithRightMedia
          title={'JOE BOYLE'}
          subTitle={'EXECUTIVE VICE PRESIDENT, COLUMBIA BRAND PRESIDENT'}
          bio={
              <Text>
                Joe Boyle joined Columbia in 2005 and has served in a variety of
                capacities of increasing leadership and responsibility, including
                brand management, sales, planning, General Merchandising Manager
                of Outerwear, Accessories, Equipment, Collegiate and Licensing,
                Vice President of Apparel Merchandising, and Senior Vice President
                of Columbia Brand Merchandising & Design. He was promoted to
                Executive Vice President, Columbia Brand President in July 2017.
                From 2003 to 2005, Mr. Boyle served in a business development role
                for Robert Trent Jones II Golf Course Architects. Mr. Boyle is a
                fourth-generation member of the company’s founding Boyle family
                and the son of Columbia Chairman, President and CEO Timothy P.
                Boyle.
              </Text>
          }
          media={
            <Image borderRadius={10} src={EVPColumbia} />
          }
        />
      </Container>
    </Box>
  );
}
