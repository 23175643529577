import { Box, Grid, GridItem, Heading, Image, Link } from '@chakra-ui/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

export default function StoryGrid ({ featureImage, tallImage, wellbeingImage, deiImage }) {
  return (
    <Box
        className='story-grid'
        maxW={'1100px'}
        marginX={'auto'}
        pos={'relative'}
        paddingTop={'12em'}
    >
        <Grid
            templateRows={{ base: '1fr', md: '1fr 1fr 1fr' }}
            templateColumns={'1fr .8fr'}
            gap={1}
        >
            <GridItem
                colSpan={2}
                bg={'gray.200'}
                display={'grid'}
                gridTemplateColumns={{ base: '1fr', md: '.8fr 1fr' }}
            >
                <Image
                    src={featureImage}
                    alt='Our History'
                    width={'100%'}
                    height={'auto'}
                />
                <Box
                    bg={'#78785F'}
                    color={'white'}
                    padding={'var(--space-m)'}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'flex-end'}
                >
                    <Box
                        className='text-component'
                    >
                        <Heading as={'h3'}
                            fontSize={'var(--step-2)'}
                        >
                            Our History
                        </Heading>
                        <Link
                            href='/our-company/our-history'
                            display={'flex'}
                            alignItems={'center'}
                        >
                            Learn More
                            <ArrowForwardOutlinedIcon 
                                fontSize='inherit' 
                                className='readmore-icon' 
                            />
                        </Link>
                    </Box>   
                </Box>
            </GridItem>
            <GridItem
                colSpan={{ base: 2, md: 1 }}
                rowSpan={1}
                bg={'#78785F'}
                bgImage={`linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.7)) , url(${wellbeingImage})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                color={'white'}
                minH={'200px'}
                padding={'var(--space-m)'}
                display={'flex'}
                flexDir={'column'}
                justifyContent={'flex-end'}
            >
                <Box
                    className='text-component'
                >
                        <Heading as={'h3'}
                            fontSize={'var(--step-2)'}
                        >
                            Wellbeing & Benefits
                        </Heading>
                        <Link
                            href='/our-company/wellbeing-benefits'
                            display={'flex'}
                            alignItems={'center'}
                        >
                        Learn More
                        <ArrowForwardOutlinedIcon 
                            fontSize='inherit' 
                            className='readmore-icon' 
                        />
                    </Link>
                </Box>
                
            </GridItem>
            <GridItem
                colSpan={{ base: 2, md: 1 }}
                rowSpan={{ base: 1, md: 2 }}
                bg={'green'}
                minH={'200px'}
                bgImage={`linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.7)) , url(${tallImage})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                color={'white'}
                padding={'var(--space-m)'}
                display={'flex'}
                flexDir={'column'}
                justifyContent={'flex-end'}
            >
                <Box
                    className='text-component'
                >
                    <Heading as={'h3'}
                        fontSize={'var(--step-2)'}
                    >
                        Corporate Responsibility
                    </Heading>
                    <Link
                        href='/corporate-responsibility-group/?tab-7924f1d2e71de065b6f='
                        display={'flex'}
                        alignItems={'center'}
                    >
                        Learn More
                        <ArrowForwardOutlinedIcon 
                            fontSize='inherit' 
                            className='readmore-icon' 
                        />
                    </Link>
                </Box>
            </GridItem>
            <GridItem
                colSpan={{ base: 2, md: 1 }}
                rowSpan={1}
                bg={'#674936'}
                bgImage={`linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.7)) , url(${deiImage})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                bgPos={'center'}
                color={'white'}
                minH={'200px'}
                padding={'var(--space-m)'}
                display={'flex'}
                flexDir={'column'}
                justifyContent={'flex-end'}
            >
                <Box
                    className='text-component'
                >
                    <Heading as={'h3'}
                        fontSize={'var(--step-2)'}
                    >
                            Diversity, Equity, & Inclusion
                    </Heading>
                    <Link
                        href='/our-company/inclusion-diversity'
                        display={'flex'}
                        alignItems={'center'}
                    >
                        Learn More
                        <ArrowForwardOutlinedIcon 
                            fontSize='inherit' 
                            className='readmore-icon' 
                        />
                    </Link>
                </Box>
                
            </GridItem>
        </Grid>
    </Box>
  );
};
