import { Box, Container, Link, Stack, Text, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import { FaXTwitter, FaYoutube, FaLinkedin } from "react-icons/fa6";
import { SiGlassdoor } from 'react-icons/si';

import SocialButton from '../../../components/sections/socialbutton';
import footerNavItems from './footerNavItems.js';
import footerNavLegal from './footerNavLegal.js';

export default function SmallCentered() {
  return (
    <Box
      width="100%"
      bg="black"
      color="white">
      <Container
        maxW="6xl"
        pt={'var(--space-l)'}
        pb={'var(--space-m)'}
        spacing={4}
        justify="center"
        align="center">
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={5}
            textAlign={'left'}
            >
            <GridItem>
              <Heading as={'h6'} variant={'h6'}>Navigation</Heading>
              <Stack as='ul' direction="column" spacing={'var(--space-3xs)'} lineHeight={'1.1'} className='footer-navList'>
                {footerNavItems.map((navItem) => (
                  <Box as='li' key={navItem.label} className={navItem.label.replace(/ +/g, '-').toLowerCase()}>
                      <Link
                        className='footer-nav__link'
                        href={navItem.href ?? '#'}
                        color="white"
                        isExternal={navItem.isExternal}>
                        {navItem.label}
                      </Link>
                    </Box>
                ))}
                </Stack>
            </GridItem>
            <GridItem>
              <Heading as={'h6'} variant={'h6'}>Legal</Heading>
              <Stack as='ul' direction="column" spacing={'var(--space-3xs)'} lineHeight={'1.1'} className='footer-navList'>
                {footerNavLegal.map((navItem) => (
                  <Box as='li' key={navItem.label} className={navItem.label.replace(/ +/g, '-').toLowerCase()}>
                      <Link
                        className='footer-nav__link'
                        href={navItem.href ?? '#'}
                        color="white"
                        isExternal={navItem.isExternal}>
                        {navItem.label}
                      </Link>
                    </Box>
                ))}
                </Stack>
            </GridItem>
            <GridItem>
            <Heading as={'h6'} variant={'h6'}>Follow us</Heading>
              <Stack direction="column" align="left">
                <Stack direction="row" spacing={4}>
                  <SocialButton label="CSC YouTube" href="https://www.youtube.com/user/ColumbiaSportswear">
                    <FaYoutube size="24px" />
                  </SocialButton>
                  <SocialButton label="CSC LinkedIn" href="https://www.linkedin.com/company/columbia-sportswear">
                    <FaLinkedin size="24px" />
                  </SocialButton>
                  <SocialButton label="CSC Twitter" href="https://twitter.com/ColumbiaCareers">
                    <FaXTwitter size="24px" />
                  </SocialButton>
                  <SocialButton label="CSC GlassDoor" href="https://www.glassdoor.com/Jobs/Columbia-Sportswear-Jobs-E3853.htm">
                    <SiGlassdoor size="20px" />
                  </SocialButton>
                </Stack>
              </Stack>
            </GridItem>
          </Grid>
        
      </Container>

      <Box>
        <Container
          maxW="6xl"
          >
          <Flex
            pt={'var(--space-xs)'}
            pb={'var(--space-l)'}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
            flexDir={{ base: 'column', md: 'row' }}>
            <Text className='footer-copyright'>©2009-2023 Columbia Sportswear Company. All rights reserved.</Text>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}
