import React from 'react';
import {
  Image,
  Box,
  Text,
  Heading,
  SimpleGrid,
  LinkBox,
  LinkOverlay,
  Button, 
  Icon,
  Container
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import HeroWithRightMedia from '../../components/sections/herowithrightmedia';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia'
import ReactPlayer from 'react-player/youtube';

import VolunteerProjectsImg from '../../components/ui/corporateresponsibility/empoweringpeople/Backyard-Collective-1200x942.jpeg'
import SupplyChainImg from '../../components/ui/corporateresponsibility/empoweringpeople/Image-14-opt.jpeg'
import LocalCommunitiesImg from '../../components/ui/corporateresponsibility/empoweringpeople/OSU-collegiate-image-1200x800.jpeg'
import OutdoorParticipationImg from '../../components/ui/corporateresponsibility/empoweringpeople/S20_DRY_HI_MG_5365-600x406.jpeg'
import InspiringPeopleImg from '../../components//ui/corporateresponsibility/empoweringpeople/inspiring-employees.jpeg'
import HeroWithTopMedia from '../../components/sections/herowithtopmedia';

export default function EmpoweringPeopleTab() {
  return (
    <Box>
      <Seo
        title="Corporate Responsibility - Empowering People"
        description="Empowering people in our supply chain, inspiring employees, and supporting outdoor particpation, access, and stewardship."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW="6xl">
      <Heading as={'h1'} mt={5}>Empowering People</Heading>
        <SimpleGrid align={'center'} columns={3} spacing={10} py={4}>
          <HeroWithTopMedia
            title={<Text>EMPOWERING PEOPLE IN OUR SUPPLY CHAIN</Text>}
            subTitle="Empowering employees in our supply chain by supporting vendors' commitments to worker well-being programs, community programs, and advanced worker communication channels."
            media={
              <Image width="100%" borderRadius={10} src={SupplyChainImg} />
            }
          ></HeroWithTopMedia>
          <HeroWithTopMedia
            title="INSPIRING EMPLOYEES AND HAVING A POSITIVE IMPACT ON LOCAL COMMUNITIES"
            subTitle="Supporting local communities by providing opportunities that enable employees and business partners to give back to their communities through paid volunteer time and donation match program."
            media={
              <Image
                borderRadius={10}
                src={InspiringPeopleImg}
              />
            }
          ></HeroWithTopMedia>
          <HeroWithTopMedia
            title="SUPPORTING OUTDOOR PARTICIPATION, ACCESS AND STEWARDSHIP"
            subTitle="Investing in outdoor education, protection and restoration to inspire the next generation and activating a healthy community of outdoor consumers to protect the spaces we love."
            media={<Image borderRadius={10} src={OutdoorParticipationImg} />}
          ></HeroWithTopMedia>
        </SimpleGrid>
        <Heading size="xl" fontFamily="GerTT">
          FEATURED INITIATIVES
        </Heading>

        <HeroWithRightMedia
          title={
            <Box>
              <Text fontWeight={'bold'} fontSize="md" mb="8">
                COLLEGIATE OUTDOOR RECREATION PROGRAM
              </Text>
              <Text fontSize="xs" mb="4">
                Columbia Sportswear Company’s Collegiate Outdoor Recreation
                Program supports outdoor participation among university students
                by providing gear and equipment to selected schools. This gear
                includes tents, sleeping bags, packs along with critical pieces
                of individual gear like waterproof boots, rain shells and
                insulating layers. These donations allow students new to the
                outdoors to have confidence that the weather doesn’t have to
                keep them inside. We provide the product to keep them warm, dry
                and protected so that they have an incredible outdoor
                experience.
              </Text>
            </Box>
          }
          media={<Image borderRadius={10} src={LocalCommunitiesImg} />}
        />
        <HeroWithLeftMedia
          title={
            <Box>
              <Text fontWeight={'bold'} fontSize="md" mb="8">
                "THANKS TO HER"
              </Text>
              <Text fontSize="xs" mb="4">
                Women represent around 75% of Columbia Sportswear Company's
                global supply chain workforce. We are proud to help support and
                empower them through HERproject workplace training and
                enrichment programs. Since starting the program in 2008, we have
                positively impacted the lives of over 37,000 workers in seven
                countries through the HERhealth, HERfinance and HERrespect
                programs.
              </Text>
            </Box>
          }
          cta={
            <LinkBox as="a">
              <LinkOverlay
                href="https://www.columbia.com/her_project_pluscolsportswear.html"
                isExternal
              >
                <Button
                  size="lg"
                  px={6}
                  bg="black"
                  color="white"
                  borderRadius={66}
                >
                  LEARN MORE
                  <Icon
                    color="white"
                    w={5}
                    h={5}
                    as={ExternalLinkIcon}
                    pl="4px"
                  />
                </Button>
              </LinkOverlay>
            </LinkBox>
          }
          media={
            <ReactPlayer
              align-items="center"
              url="https://www.youtube.com/embed/g3XXMqhcEk8?autoplay=0&rel=0&enablejsapi=1&wmode=opaque"
            />
          }
        />
        <HeroWithRightMedia
          title={
            <Box>
              <Text fontWeight={'bold'} fontSize="md" mb="8">
                GLOBAL EMPLOYEE VOLUNTEER PROJECTS
              </Text>
              <Text fontSize="xs" mb="4">
                Every employee of Columbia Sportswear Company is awarded paid
                volunteer hours per year for volunteer activities. Employees can
                choose the organization(s) they want to support individually, as
                well as participate in annual volunteer days organized by each
                office around the world.
              </Text>
            </Box>
          }
          media={<Image borderRadius={10} src={VolunteerProjectsImg} />}
        />
      </Container>
    </Box>
  );
}
