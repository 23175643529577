import React from 'react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import BlogPost from '../../../components/sections/blogpost';
import mhwRepairHeroImg from '../../../components/ui/corporateresponsibility/impact/stories/mhw-repair.jpg';
import Callout from '../../../components/sections/callout';

import mhwWarrantyImg from '../../../components/ui/corporateresponsibility/impact/stories/mhw_warranty.webp';

export default function RepairMountainHardwear() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility | Impact - Repair Services at Mountain Hardwear"
                description="The Mountain Hardwear Warranty team ensures they are doing everything they can to extend the use of an item, whether it’s repairing a zipper or donating the product to a local non-profit."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <BlogPost 
                title='Repair Services at Mountain Hardwear'
                featuredImage={ mhwRepairHeroImg }
                featuredAlt='Smiling teammates after volunteer event'
                postContent={<>
                        <p>
                        Equipment that lasts is not only vital for performance on the mountain, it’s also important for keeping it out of landfills. The Mountain Hardwear Warranty team can give products a new life. From beginning to end, the Warranty team ensures they are doing everything they can to extend the use of an item, whether it’s repairing a zipper or donating the product to a local non-profit.
                        </p><p>
                        It all starts with a claim. For example, say the zipper on one of our jackets breaks and it is no longer useful in the frigid temperatures it’s intended for. Rather than throwing the jacket out and buying a new one, customers can submit a claim and send that item to the Mountain Hardwear Warranty team.
                        </p><p>
                        Once the team receives the product, they inspect it and let the customer know whether or not it can be repaired. As long as the product is covered under the warranty policy, it will either be repaired or the customer will be issued a Virtual Merchandise Credit.
                        </p><p>
                        Product repairs are done by our talented team of experienced seamstresses who repair up to four items a day, depending on how extensive the repairs are. The team assesses each product they receive and determines the best repair, sometimes having to get a little creative. In one instance, the sleeves had been cut off from a collection of a customer's jackets, so the team gave the jackets a second life as vests. As Cindy Ng, Product Repair Associate, says, one of the best parts of the job is getting to “design the repair.”
                        </p><p>
                        To encourage long-lasting products, the team includes education as part of the process. Aftercare, such as how to properly clean or wash items without damaging them, is one of the most important parts of the job, according to Armondo Gomez, Warranty Representative.
                        </p><p>
                        But what happens to a product that cannot be repaired? The team works with local non-profits to donate the products that still have some functionality to them. Product that is no longer wearable is donated for scrap pieces, concluding the lifecycle of that particular garment.
                        </p><p>
                        And bringing it full circle, the Warranty team reports any frequent claims to the Product Development team, so they can take that information into account when designing for future seasons.
                        </p>
                        <Callout
                            bkg_color={'var(--mhw-yellow)'}
                            media={ mhwWarrantyImg }
                            headline={'We repair everything we can'}
                            description={'Equipment is better on the mountain, not in the landfill.'}
                            link_text={"Learn more about MHW's Warranty program"}
                            link_url="https://www.mountainhardwear.com/learn/sustainability-efforts/repair-over-replace.html"
                        />
                        </>}
            />
        </HomeLayout>

        

    );
}