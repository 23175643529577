import { Stack, Flex, Heading, Box } from '@chakra-ui/react';

export default function BioWithLeftMedia({ title, subTitle, cta, media, bio }) {
  return (
      <Stack
        align="center"
        alignItems={'flex-start'}
        spacing={{ base: 10 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Flex
          flex={1}
          position="relative"
          w="full">
          { media }
        </Flex>
        <Stack flex={1}>
          <Heading
            fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
            mb={0}
            >
            { title }
          </Heading>
          <Heading mb={4} fontSize={'md'}>
            { subTitle }
          </Heading>
          <Box className='flow'>
            { bio }
          </Box>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            { cta }
          </Stack>
        </Stack>
      </Stack>

  );
}
