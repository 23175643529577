import { Container, Stack, Flex, Heading, Text } from '@chakra-ui/react';

export default function HeroWithLeftMedia({ title, subTitle, cta, media }) {
  return (
    <Container maxW="6xl">
      <Stack
        align="center"
        spacing={{ base: 5 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full">
          { media }
        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
            { title }
          </Heading>
          <Text whiteSpace="pre-line">
            { subTitle }
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            { cta }
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
