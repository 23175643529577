import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl'
import ReactGA from 'react-ga4';
import App from './App';
import theme from './utils/theme';
import Fonts from './utils/fonts';
import styles from './utils/style';
import './css/style.css'

ReactGA.initialize([
  {
  trackingId: "G-X6XPY42TYN",
  gaOptions: {
    'alwaysSendReferrer': true
  },
  },
]);

const emotionCache = createCache({
  key: 'emotion-css-cache',
  prepend: true, // ensures styles are prepended to the <head>, instead of appended
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const targetLocale = "en";
const messages = [];

root.render(
  <StrictMode>
    <IntlProvider messages={messages} locale={targetLocale} defaultLocale="en">
      <CacheProvider value={emotionCache}>
        <ChakraProvider theme={styles}>
          <Fonts />
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </CacheProvider>
    </IntlProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
