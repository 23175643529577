import React from 'react';
import { Image, Box, Text, Container, Heading } from '@chakra-ui/react';
import ReactPlayer from 'react-player/vimeo';
import Seo from '../../../components/sections/seo';

import TextWithRightMedia from '../../../components/sections/textwithrightmedia';
import TextWithLeftMedia from '../../../components/sections/textwithleftmedia';
import CtaShort from '../../../components/sections/ctashort';

import engineeringForTheExperienceImg from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_EngineeringForTheExperience.png';
import thinkFreelyImg from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_ThinkFreely.png';
import buildingToLastImg from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_BuildingToLast.png';
import nurturingFeedbackLoopImg from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_NurturingAFeedbackLoop.png';
import ctaBackgroundImg from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_JobOppourtunities.png';
import whyWorkMhw01Img from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_WhyWorkAtMHW_01.png';
import whyWorkMhw02Img from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_WhyWorkAtMHW_02.png';
import whyWorkMhw03Img from '../../../components/ui/ourbrands/mhw/MHW_Careers_Page_WhyWorkAtMHW_03.png';

export default function MhwTabPanel() {
  return (
    <Box>
      <Seo
        title="Careers at Mountain Hardwear"
        description="Find your next career at Mountain Hardwear."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='7xl' my={14} className='flow'>
        <Box 
          as={'section'}
          textAlign={'center'}
        >
          <Box
            className='mw-prose flow'
            mx={'auto'}
          >
            <h1>
              Help us equip more people to seek a wilder path
            </h1>
            <Text>
              Nothing gets us going like spending time in wild places. Because being in the wild just does something to you. You come home and you’re just different. A little wilder. A little wiser. We are passionate about using our wild wisdom to create the best products to help more people grow and evolve outdoors. We tend to be innovative and creative, enjoy challenges, and are always looking for bold ideas.
            </Text>
          </Box>
        </Box>
      <Heading 
        textAlign={'center'}
        sx={{'--flow-space': 'var(--space-2xl)'}}
      >
        We take pride in
      </Heading>
      <TextWithRightMedia
        title={'Engineering for the experience'}
        media={<Image src={engineeringForTheExperienceImg} />}
        content={
          <>
            <Text>
              For 30 years, we have built essential equipment for climbers, mountaineers, and outdoor athletes and have supported expeditions on the world’s highest peaks. Relentless precision continues to inspire everything we do—our designers sweat every stitch and detail to continuously improve function, durability, and comfort.
            </Text>
          </>}
      />
      <TextWithLeftMedia
        title={'Thinking freely'}
        media={<Image src={thinkFreelyImg} />}
        content={
          <>
          <Text>
            Questions excite us, giving us permission to experiment outside the bounds. We poke and prod to envision new ways of making and being beyond conventions and stereotypes. 
          </Text>
          </>
        }
      />
      <TextWithRightMedia
        title={'Building to last'}
        media={<Image src={buildingToLastImg} />}
        content={
          <>
            <Text>
              We are dedicated to preserving nature. For us, that means creating fewer things that stand the test of time. We use recycled materials, re-engineer old products into new creations and repair old gear because durability is sustainability.
            </Text>
          </>}
      />
      <TextWithLeftMedia
        title={'Nurturing a feedback loop'}
        media={<Image src={nurturingFeedbackLoopImg} />}
        content={
          <>
          <Text>
            From employee product testing trips to feedback sessions with athletes, mountain guides, and our in-house team of sewers, we are constantly foraging information from first-hand sources to create something that lasts.
          </Text>
          </>
        }
      />
      <CtaShort
        button_text={'Job Opportunities'}
        button_url={'https://columbiasportswearcompany.wd5.myworkdayjobs.com/Mountain_Hardwear_Careers?source_id=csccom'}
        backgroundImage={ctaBackgroundImg}
      />
      <Box as='section' className='mw-prose flow' textAlign={'center'} mx={'auto'}>
        <Heading mt={'var(--space-2xl);'}>Why work at Mountain Hardwear</Heading>
        <Text>
          We are all proud to be part of Mountain Hardwear. We like our small-company feel, team orientation, down-to-earth approach and casual get-after-it atmosphere. Many of us get outside as much as possible, test our product, challenge ourselves, and stay connected to the outdoor joy and friends that led us into this field.
        </Text>
        <Text>
          In work and outdoors, Mountain Hardwear helps people find and push the edges of their potential, grow beyond what they thought is possible, and evolve into their best selves.
        </Text>
      </Box>
      <TextWithLeftMedia
        media={<Image width={'80%'} src={whyWorkMhw01Img} />}
        content={
          <>
          <Text>
            Just a few strides off the San Francisco ferry, a short bike ride up the East Bay waterfront, or across the bridge from Marin County, you’ll find our Bay Area office at the historic Ford Point, formally the Ford Motor Company Assembly Plant, in Richmond, California. 
          </Text>
          </>
        }
      />
      <TextWithRightMedia
        media={<Image src={whyWorkMhw02Img} />}
        content={
          <>
            <Text>
              Inside, we have, at the heart of our company, our in-house Warranty & Repair Center, where a team of world-class sewers are hard at work to make sure we are standing by our product and repair everything we can.
            </Text>
          </>}
      />
      <TextWithLeftMedia
        media={<Image width={'80%'} src={whyWorkMhw03Img} />}
        content={
          <>
          <Text>
            Under all the buzz—the coffee grinder churning, keyboards typing, sewing machines humming, and the occasional bark of dogs saying hello—you’ll hear the chatter of stories. Behind everything we do is our own real-life experiences.
          </Text>
          </>
        }
      />
      <TextWithRightMedia
        title={'It stems from lived experience'}
        media={<ReactPlayer url='https://player.vimeo.com/video/856495326?h=50215e78cc' controls={true} />}
        content={
          <>
            <Text>
              We take our Seek Wilder Paths philosophy to heart. From our home turf in the East Bay to Mount Everest, the ultimate test for Mountain Hardwear product has always been using it ourselves. We continue to provide opportunities for employees to experience these places and our gear firsthand and build the understanding and camaraderie it takes to make better outdoor equipment.
            </Text>
          </>}
      />
      
      </Container>
    </Box>
  )
}
