import { LinkBox, LinkOverlay, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';

export default function SocialButton({children, label, href }) {
  return (
    <LinkBox>
      <LinkOverlay className='social-button' href={href} label="mylink" isExternal>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </LinkOverlay>
    </LinkBox>
  );
};
