import { Box } from '@chakra-ui/react';

export default function CtaShort({ button_url, button_text, backgroundImage, backgroundColor, secondary_url, secondary_text }) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={4}
            alignItems={'center'}
            sx={{'--flow-space': 'var(--space-xl)'}}
            bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${backgroundImage})`}
            bgColor={`${backgroundColor}`}
            bgSize="cover"
            bgRepeat="no-repeat"
            height={'175px'}
            >
                <Box 
                    as='a'
                    className='btn btn-black btn-medium pill-border'
                    href={button_url}
                    target='_blank'
                >
                    {button_text}
                </Box>

                { secondary_url ? 
                
                <Box
                    className='secondary-link'
                    as='a'
                    href={secondary_url}
                    target='_blank'
                    color={'white'}
                >
                    {secondary_text}
                </Box>

                :
                null }
        </Box>
    );
}