import React, { useState } from 'react';
import { Box, IconButton, useBreakpointValue, Image, Card, LinkBox, Text, LinkOverlay } from '@chakra-ui/react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Slider from 'react-slick';

export default function CarouselStories({cards}) {
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      }
    ]
  };

  const [slider, setSlider] = useState(null);
  const top = useBreakpointValue({ base: '40%', md: '38%' });
  const side = useBreakpointValue({ base: '11%', sm: '7%',  md: '42px' });

  return (
    <Box
      position="relative"
      width="full"
      >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        height={16}
        backgroundColor={'white'}
        borderRadius={1}
        opacity={.9}
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        ml={-8}
        _hover={{
            opacity: "1"
        }}
        onClick={() => slider?.slickPrev()}>
        <ChevronLeftOutlinedIcon />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        height={16}
        backgroundColor={'white'}
        borderRadius={1}
        opacity={.9}
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        mr={-8}
        _hover={{
            opacity: "1"
        }}
        onClick={() => slider?.slickNext()}>
        <ChevronRightOutlinedIcon />
      </IconButton>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards && cards.map((card, index) => (
          <LinkBox
            as='article'
            key={index}
            position="relative"
            px={2}
            height={'100%'}>
            <Card 
                variant="unstyled"
                position="relative"
                align="center"
                bgColor={'gray.100'}
                height={'100%'}
            >
                <Image
                    objectFit='cover'
                    src={card.image}
                    alt='{card.alt}'
                    height='175px'
                    width={'100%'}
                />
                <Box 
                    align="center"
                    padding={6}
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    flex={'1 0 auto'}
                >
                    
                        <h3>{card.heading}</h3>
                        {card.description ? (<Text marginTop={3} sx={{ '--flow-lh': '1.4' }} fontSize={'var(--step--1)'}>{card.description}</Text>) : ( null )}
                        <LinkOverlay 
                          href={card.url}
                          marginTop={'auto'}
                          paddingTop={'5'}
                        >
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                fontWeight={'700'}
                                fontSize={'sm'}
                            >
                                Read More
                                <Box as='span' mt='6px'>
                                    <ArrowForwardOutlinedIcon fontSize={'1rem'}  />
                                </Box>
                            </Box>
                        </LinkOverlay>
                    
                </Box>
            </Card>
          </LinkBox>
        ))}
      </Slider>
    </Box>
  );
}
