import { VStack, Stack, Heading, Text, Box } from '@chakra-ui/react';

export default function HeroWithStackedMedia({ title, subTitle, cta, media }) {
  return (
   
      <Box>
        <VStack
          align={'center'}
          spacing={{ base: 5 }} >
          <Stack>
            <Heading
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
              textAlign={'center'}>
                { title }
            </Heading>
            <Text whiteSpace={'pre-line'}>
              { subTitle }
            </Text>
          </Stack>
          <Stack
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            { media }
          </Stack>
        </VStack>
      </Box>
    
  );
}
