import React from 'react';
import {
  Text,
  Container,
  Heading,
  Stack,
  Flex,
  VStack,
  useBreakpointValue,
  Image,
  Box,
  SimpleGrid,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';
import HomeLayout from '../../components/layouts/homelayout'
import QuoteWithLeftMedia from '../../components/sections/quotewithleftmedia'
import QuoteWithRightMedia from '../../components/sections/quotewithrightmedia'

import WellbeingHeroImg from '../../components/ui/ourcompany/wellbeing/wellbeing_banner_2020_wide.jpg'
import seanJany from '../../components/ui/ourcompany/wellbeing/SEAN_JANY_V3_1.jpg'
import AngelaDunn from '../../components/ui/ourcompany/wellbeing/ANGELA_DUNN_A6A1539_cr.jpg'

export default function Wellbeing() {
  return (
    <HomeLayout align="left">
    <Seo
        title="Wellbeing and Benefits"
        description="Employee wellbeing is the foundation of our Total Rewards philosophy."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
       <Stack w="full" className='page-banner'></Stack>
        <Flex
            w={'full'}
            h={'60vh'}
            backgroundImage={WellbeingHeroImg}
            backgroundSize={'cover'}
            backgroundPosition={'center center'}
            id="wellbeingHeroImg"
        >
            <VStack
            w={'full'}
            justify={'flex-end'}
            align={'left'}
            pb={useBreakpointValue({ base: 4, md: 12 })}
            bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
            >
            <Container maxW={'6xl'}>
                <Heading
                    as={'h1'}
                    color={'white'}>
                We Ignite Human Potential for Growth
                </Heading>
            </Container>
            </VStack>
        </Flex>
        <Container maxW={'6xl'} className='flow' py={12}>
            <Heading>
                Total Rewards begins with wellbeing.
            </Heading>
            <Text>
                At Columbia Sportswear Company (CSC), our mission is to connect active people with their passions. And we know that passions carry you far beyond work.
            </Text>
            <Text>
                To get the most out of life and work no matter where your passions take you, you need to feel your best — physically, mentally, emotionally, socially, and financially. Our Total Rewards package includes the programs and plans you’d expect, such as market-competitive compensation, 401k, and health benefits. What makes us different is that employee wellbeing is the foundation of our Total Rewards philosophy.
            </Text>
            <Text>
                While most corporate wellbeing programs focus on medical-based wellness initiatives, at CSC we believe wellbeing must be considered holistically. So, we align our benefits, compensation, and employee programs against the five fundamental elements of wellbeing to ensure that you can be your best self.
            </Text>
            <QuoteWithLeftMedia media={<Image borderRadius={10} src={seanJany} />} quote={`“There’s always something going on, whether it’s sports, workout classes, health seminars, or personal development workshops. Columbia helps all employees connect to their passions.”`} speaker="– Sean Jany, Global Supply Chain" />
            <Heading>
                Benefits for Being the Best
            </Heading>
            <Text>
                Because Wellbeing is the principal tenet of our Total Rewards philosophy, our benefits go well beyond standard and reflect the diverse needs of our employees.
            </Text>
            <Text>
                While each benefit aligns with an individual element of Wellbeing, they often overlap with other elements. This overlapping structure ensures that we’re creating as many opportunities as possible to positively affect holistic employee wellbeing.
            </Text>
            <Text>
                We’re proud to offer our employees an array of options to choose from when customizing their benefits package.
            </Text>
            <Text>
                Here’s a small sample:
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 0, md: 10}}>
                <Box>
                    <UnorderedList>
                        <ListItem>Medical, Dental, and Vision</ListItem>
                        <ListItem>401(k) with generous match and immediate vesting</ListItem>
                        <ListItem>Flexible Paid Time Off</ListItem>
                        <ListItem>Time off to Volunteer</ListItem>
                        <ListItem>Paid Parental Leave</ListItem>
                        <ListItem>Continuing Education Programs</ListItem>
                        <ListItem>Student Loan Management</ListItem>
                    </UnorderedList>
                </Box>
                <Box>
                    <UnorderedList>
                        <ListItem>Financial Wellness Programs</ListItem>
                        <ListItem>Enhanced Employee Assistance Program</ListItem>
                        <ListItem>Charitable Giving Match</ListItem>
                        <ListItem>Commuter and Travel Benefits</ListItem>
                        <ListItem>Fitness Programs and Discounts</ListItem>
                        <ListItem>Outdoor Activities</ListItem>
                        <ListItem>And many more…</ListItem>
                    </UnorderedList>
                </Box>
            </SimpleGrid>
            <Text>
                Because we have employees all over the world and in a variety of roles, eligibility for benefits and programs varies by location and role.
            </Text>
            <Heading>
                Life is a grand adventure. Live it well.
            </Heading>
            <Text>
                Whatever employees and their families aspire to become, we provide them with the tools and resources to live balanced and healthy lives in and out of work. So, they can stay connected with their passions, igniting their potential for personal and professional growth.
            </Text>
            <QuoteWithRightMedia media={<Image borderRadius={10} src={AngelaDunn} />} quote={`“Columbia helps me stay healthy by giving me the time and opportunity to work out and manage my wellness. This keeps me mentally, emotionally, and physically fit so I can stay energized and tackle anything that comes my way.”`} speaker="– Angela Dunn, Global Human Resources" />
      </Container>
    </HomeLayout>
  );
}
