const PosterItems = [
    { poster: "SMP Poster – English", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_Manufacturing_Poster.pdf"},
    { poster: "SMP Poster – Arabic", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Arabic.pdf"},
    { poster: "SMP Poster – Bengali", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Bengali.pdf"},
    { poster: "SMP Poster – Burmese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Burmese.pdf"},
    { poster: "SMP Poster – Hebrew", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Hebrew.pdf"},
    { poster: "SMP Poster – Hindi", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Hindi.pdf"},
    { poster: "SMP Poster – Indonesian", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Indonesian.pdf"},
    { poster: "SMP Poster – Italian", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Italian.pdf"},
    { poster: "SMP Poster – Japanese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Japanese.pdf"},
    { poster: "SMP Poster – Kannada", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Kannada.pdf"},
    { poster: "SMP Poster – Khmer", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Khmer.pdf"},
    { poster: "SMP Poster – Korean", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Korean.pdf"},
    { poster: "SMP Poster – Malay", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Malay.pdf"},
    { poster: "SMP Poster – Portuguese (Brazil)", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Portuguese_Brazil.pdf"},
    { poster: "SMP Poster – Simplified Chinese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Simplified_Chinese.pdf"},
    { poster: "SMP Poster – Singhalese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Singhalese.pdf"},
    { poster: "SMP Poster – Spanish (LatAm)", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Spanish_LatAm.pdf"},
    { poster: "SMP Poster – Tagalog", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Tagalog.pdf"},
    { poster: "SMP Poster – Tamil", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Tamil.pdf"},
    { poster: "SMP Poster – Thai", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Thai.pdf"},
    { poster: "SMP Poster – Traditional Chiense", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Traditional_Chinese.pdf"},
    { poster: "SMP Poster – Turkish", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Turkish.pdf"},
    { poster: "SMP Poster – Urdu", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Urdu.pdf"},
    { poster: "SMP Poster – Vietnamese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Standards_of_Manufacturing_Practices_2018_Poster_Vietnamese.pdf"},
    { poster: "CSC Vendor Social Responsibility Manual", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/Social_Responsibility_Manual_for_Manufacturing_Partners.pdf"},
    { poster: "CSC Foreign Migrant Worker Policy", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_Foreign_Migrant_Worker_Policy.pdf"}
];

const CSCPolicies = [
    {policyName: "CSC Restricted Substances List (Apparel) – English", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_English.pdf"},
    {policyName: "CSC Restricted Substances List (Apparel) – Chinese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_Chinese.pdf"},
    {policyName: "CSC Restricted Substances List (Apparel) – Japanese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_Japanese.pdf"},  
    {policyName: "CSC Restricted Substances List (Apparel) – Korean", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_Korean.pdf"}, 
    {policyName: "CSC Restricted Substances List (Apparel) – Vietnamese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL&Product_Safety_Manual_Vietnamese.pdf"}, 
    {policyName: "CSC Restricted Substances List (Footwear) – English", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL%20and%20Product%20Safety%20Manual%20(Footwear)_%20English.pdf"}, 
    {policyName: "CSC Restricted Substances List (Footwear) – Chinese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL%20and%20Product%20Safety%20Manual%20(Footwear)_Chinese.pdf"},
    {policyName: "CSC Restricted Substances List (Footwear) – Japanese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL_and_Product_Safety_Manual_FW_Japanese.pdf"}, 
    {policyName: "CSC Restricted Substances List (Footwear) – Vietnamese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_RSL%20and%20Product%20Safety%20Manual%20(Footwear)_Vietnamese.pdf"}, 
    {policyName: "Chemical Management Policy – English", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_CM_Policy_Guideline_English.pdf"}, 
    {policyName: "Chemical Management Policy – Chinese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_CM_Policy_Guideline_Simplified_Chinese.pdf"}, 
    {policyName: "Chemical Management Policy – Korean", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_CM_Policy_Guideline_Korean.pdf"}, 
    {policyName: "Chemical Management Policy – Vietnamese", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Standards_Policies_Manuals/CSC_CM_Policy_Guideline_Vietnamese.pdf"}
];

const PSListItems = [ 
    { title: "Climate Change", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Climate_Change.pdf" },
    { title: "Animal-Derived Materials Policy", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Animal_Derived_Materials_Policy.pdf"},
    { title: "Natural Down & Feather", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Natural_Down&Feather.pdf"},
    { title: "Uzbekistan and Turkmenistan Cotton", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Uzbekistan&Turkmenistan_Cotton_Statement.pdf"},
    { title: "Per- and Poly Fluoroalkyl Substances", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/PFAS_Statement.pdf"},
    { title: "Conflict Minerals Policy", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Position_Statements/Conflict_Minerals.pdf"},
    { title: "Transparency in Supply Chain Statement", link: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Supply_Chain/Transparency_in_Supply_Chain_Statement.pdf"}
];

module.exports = {
    CSCPolicies,
    PSListItems,
    PosterItems
};