const footerNavItems = [
    {
        label: "Careers",
        href: "/careers",
    },
    {
        label: "Our Company",
        href: "/our-company/our-history"
    },
    {
        label: "Corporate Responsibility",
        href: "/corporate-responsibility-group/?tab-7924f1d2e71de065b6f="
    },
    {
        label: "News",
        href: "https://investor.columbia.com/news-events/press-releases",
        isExternal: true
    },
    {
        label: "Investor Relations",
        href: "https://investor.columbia.com/",
        isExternal: true
    }
];

export default footerNavItems;
