import { Box, Stack, Heading, Text } from '@chakra-ui/react';

export default function HeroWithTopMedia({ title, subTitle, cta, media }) {
  return (
    <Box maxW={'7xl'} display={'flex'} flexDir={'column'} position={'relative'}>
      <Box
        py={{ base: 5, md: 10 }}
      >
        {media}
      </Box>
      <Stack 
        textAlign={'left'}
        flexGrow={1}
        flexShrink={0}
        flexBasis={'auto'}
        >
          <Heading
            fontWeight={600}
            fontSize='lg'
            //justify={'center'}
            position={'relative'}
          >
            {title}
          </Heading>
          <Text           
            position={'relative'}
            whiteSpace={'pre-line'}
            mb={8}
            >
              {subTitle}
          </Text>
          {cta}
        </Stack>
    </Box>
  );
}
