import { Flex, LinkBox, LinkOverlay, Stack, Image } from '@chakra-ui/react';

export default function CardLink ({ title, backgroundImage, href, logo, logoWidth }) {
  return (
    <Stack>
      <LinkBox textDecoration={'none'}>
        <LinkOverlay href={href} isExternal textDecoration={'none'}>
          <Flex 
            maxW="100%"
            //minH={{base: '300px', md: '325px'}}
            minH={{base: '150px', md: '325px'}}
            justify="center"
            align="center"
            bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${backgroundImage})`}
            bgSize="cover"
            bgRepeat="no-repeat"
          >
            <Image
                src={logo}
                width={logoWidth}
                alt={title}
             />
          </Flex>
        </LinkOverlay>
      </LinkBox>
    </Stack>
  );
};
