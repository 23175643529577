import { Box, Heading, Image } from '@chakra-ui/react';
import React from 'react';

export default function WithBackgroundImage({title, backgroundImage, backgroundVideo, callToAction}) {
  return (
    <Box 
        className='video-feature'
    >
        <Box 
            className='video-feature__box-wrapper'
            textAlign={'center'}
        >
            <Box className='video-feature__box'>
                <Box 
                    className='text-component'
                    color='white'
                    paddingX={'var(--space-m)'}
                    paddingTop={'var(--space-3xl)'}
                    paddingBottom={'var(--space-xl)'}
                >
                    <Heading 
                        as='h1' 
                        fontSize={'var(--step-7)'} 
                        textTransform='none' 
                        color={'black'} 
                        lineHeight={1.1}>
                            { title }
                    </Heading>
                </Box>

                { callToAction && callToAction }
                
            </Box>
        </Box>
        <Box as='figure' className='video-feature__video-wrapper'>
            <Image src={backgroundImage}></Image>
            <Box 
                as='video'
                autoPlay loop muted playsInline
                src={backgroundVideo}
                alt='Description of video'
            />
        </Box>
    </Box>
  );
}
