import React from 'react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import BlogPost from '../../../components/sections/blogpost';
import club1938HeroImg from '../../../components/ui/corporateresponsibility/impact/stories/club-1938.jpg';

export default function Club1938() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility | Impact - Club 1938"
                description="Club 1938 is an outdoor program for our employees based in China, providing a virtual hub for sharing tips and ideas to increase knowledge about the outdoors."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <BlogPost 
                title='Club 1938'
                featuredImage={ club1938HeroImg }
                featuredAlt='Members of Club 1938 pose in front of mountains'
                postContent={<>
                        <p>
                        Club 1938 is an outdoor program for our employees based in China, providing a virtual hub for sharing tips and ideas to increase knowledge about the outdoors. In 2023, the Club published seven video stories, launched three online activities and completed two hiking events. To celebrate the one-year anniversary of the program, club members embarked on a three-day trip to Beijing Chongli, which included a celebration, an 11km hike and a tour of the Great Wall of China.
                        </p>
                        </>}
            />
        </HomeLayout>

        

    );
}