import React, { useState } from 'react';
import { Box, IconButton, useBreakpointValue, Image, Card, CardBody, Heading, Link } from '@chakra-ui/react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Slider from 'react-slick';

export default function Carousel({cards}) {
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      }
    ]
  };

  const [slider, setSlider] = useState(null);
  const top = useBreakpointValue({ base: '80%', md: '50%' });
  const side = useBreakpointValue({ base: '7%', md: '20px' });

  return (
    <Box
      position="relative"
      width="full"
      >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        height={16}
        backgroundColor={'white'}
        borderRadius={1}
        opacity={.4}
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        ml={-8}
        _hover={{
            opacity: ".8"
        }}
        onClick={() => slider?.slickPrev()}>
        <ChevronLeftOutlinedIcon />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        height={16}
        backgroundColor={'white'}
        borderRadius={1}
        opacity={.4}
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        mr={-8}
        _hover={{
            opacity: ".8"
        }}
        onClick={() => slider?.slickNext()}>
        <ChevronRightOutlinedIcon />
      </IconButton>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards && cards.map((card, index) => (
          <Box
            key={index}
            position="relative">
                <Link
                    href={card.url}
                    textDecoration={'none'}
                    isExternal
                >
                    <Card variant="unstyled"
                            key={index}
                            position="relative"
                            align="center"
                            ml={.5}
                            mr={.5}
                            >
                        <CardBody 
                            pos={'relative'} 
                            textAlign={'center'}
                            color={'white'}
                            bgImage={`linear-gradient(rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.8))`}
                        >
                            <Image 
                                src={card.image}
                                opacity={.5}
                                alt={`Jobs in ${card.heading}`}
                            />
                            <Box
                                pos={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'}
                            >
                                <Heading as={'h3'}
                                    fontSize={'var(--step-2)'}
                                    lineHeight={1.1}
                                    textTransform={'none'}
                                >
                                   
                                        {card.heading}
                                    
                                </Heading>
                            </Box>
                        </CardBody>
                    </Card>
                </Link>                   
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
