import React from 'react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import BlogPost from '../../../components/sections/blogpost';
import transitionProjectsHeroImg from '../../../components/ui/corporateresponsibility/impact/stories/transition-projects.jpg';

export default function TransitionProjects() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility | Impact - Transition Projects"
                description="For over 20 years, Columbia Sportswear has been a proud supporter of Transition Projects, providing employees with bi-weekly volunteer opportunities to serve meals to those experiencing houselessness in the Portland, Oregon area."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <BlogPost 
                title='Transition Projects'
                featuredImage={ transitionProjectsHeroImg }
                featuredAlt='Smiling teammates after volunteer event'
                postIntro='When employees are passionate about a volunteer opportunity, we want to support them.'
                postContent={<>
                        <p>
                        John Ducker, Sr Director of Supply Chain Capabilities at Columbia Sportswear and a board member of Transition Projects, organizes bi-weekly volunteer opportunities for CSC employees to serve meals to those experiencing houselessness in the Portland, Oregon area. Transition Projects, a non-profit organization dedicated to aiding those without stable housing, assists individuals in finding housing and connecting them with necessary resources.
                        </p>
                        <p>
                        For over 20 years, Columbia Sportswear has been a proud supporter of Transition Projects through the Truckload of Coats donation drive, the Veteran’s Stand Down, and employee-led groups preparing and serving meals at Transition Projects shelters. Our community support for people experiencing houselessness was recognized at the 2024 Impact Gala hosted by Transition Projects, where we were honored to receive the <a href="https://www.linkedin.com/feed/update/urn:li:activity:7184657667972296704" target='_blank' rel='noreferrer noopener'>Setting the Standard award.</a>
                        </p>
                        </>}
            />
        </HomeLayout>

        

    );
}