import React from 'react';
import { Container, Image } from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout';
import SimpleThreeColumns from '../../components/sections/simplethreecolums';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';

import gertCloudsCard from '../../components/ui/gert-clouds-card.png';

import homeVideo from '../../components/ui/csc_com_home_vid_bkg.mp4';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import BrandLockup from '../../components/sections/brandlockup';

function Home() {
  return (
    <HomeLayout align="center">
      <div className='hero-main height-100 page-banner'>
        <div className='hero-main__container'>
          <div className='hero-main__inner-container'>
            <div className='hero-main__inner center-left is-top'>
              <div className='container'>
                <div className='hero-main__content'>
                  <h1>We connect active people with their passions.</h1>
                  <BrandLockup />
                </div>
              </div>
            </div>
            <div className='hero-main__video-container'>
              <video
                width='100%'
                height='100%'
                loop
                muted
                autoPlay
                playsInline
              >
                <source src={homeVideo} type='video/mp4'></source>
              </video>
            </div>
            <div className='hero-main__caret-wrapper'>
              <figure className='hero-main__caret'>
                <KeyboardArrowDownOutlinedIcon />
              </figure>
            </div>
            <div className='hero-main__overlay'></div>
          </div>
        </div>
      </div>
      <Container maxW='6xl' my={14}>
        <SimpleThreeColumns />
        <HeroWithLeftMedia
          title={['Our Founder Gert Boyle:', <br />, 'March 6, 1924 – November 3, 2019']}
          subTitle="Gert Boyle, our “One Tough Mother,” held many jobs at Columbia, from seamstress of the first fishing vest to President to advertising icon. Her sharp wit and wisdom helped propel the company from near bankruptcy in the early ‘70s to the global multi-brand company it is today, with annual net sales of almost $3.5 billion in 2023. Her pioneering role as a woman in what was then a male-dominated industry is a testament to her strength of character and ability to persevere through difficult situations."
          // cta={<Button size="lg" px={6} bg="#333" color="white">Read More</Button>}
          media={<Image src={gertCloudsCard} />}
        />
      </Container>  
    </HomeLayout>
  );
}

export default Home;
