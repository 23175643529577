import { Container, Stack, Flex, Text } from '@chakra-ui/react';

export default function QuoteWithLeftMedia({ quote, speaker, cta, media }) {
  return (
    <Container maxW="6xl">
      <Stack
        align="center"
        spacing={{ base: 5 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Flex
          flex={1}
          alignItems="flex-start"
          justify="center"
          position="relative"
          w="full">
          { media }
        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <Text
            fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }}
            fontWeight={600}
            lineHeight={7}
            sx={{
                '--flow-lh': 'lineHeights.8',
            }}
            >
            { quote }
          </Text>
          <Text whiteSpace="pre-line">
            { speaker }
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            { cta }
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
