const config = {
  einstein: [
    {
      host: 'https://api.cquotient.com',
      proxyPath: `/mobify/proxy/einstein`,
      einsteinId: '83ed8b45-31e7-424a-af67-d8ee05646d80',
      siteId: 'bcpx-Columbia_US',
      recommenderName: 'best-sellers-hp',
      isProduction: true
    },
    {
      host: 'https://api.cquotient.com',
      proxyPath: `/mobify/proxy/einstein`,
      einsteinId: '83ed8b45-31e7-424a-af67-d8ee05646d80',
      siteId: 'bcpx-Sorel_US',
      recommenderName: 'bestsellers-excluding-sale',
      isProduction: true
    },
    {
      host: 'https://api.cquotient.com',
      proxyPath: `/mobify/proxy/einstein`,
      einsteinId: '83ed8b45-31e7-424a-af67-d8ee05646d80',
      siteId: 'bcpx-MountainHardwear_US',
      recommenderName: 'top-rated',
      isProduction: true
    },
    {
      host: 'https://api.cquotient.com',
      proxyPath: `/mobify/proxy/einstein`,
      einsteinId: '83ed8b45-31e7-424a-af67-d8ee05646d80',
      siteId: 'bcpx-Prana_US',
      recommenderName: 'pra-bestsellers',
      isProduction: true
    }
  ]
};
export default config;
