import React from 'react';
import { AspectRatio } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import BlogPost from '../../../components/sections/blogpost';
import planetWaterHeroImg from '../../../components/ui/corporateresponsibility/impact/stories/planet_water.jpg'

export default function CleanWater() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility | Impact - Clean Drinking Water"
                description="Combatting the global water crisis and improving access to clean drinking water in areas where CSC products are produced through a partnership with Planet Water Foundation."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <BlogPost 
                title='Columbia Sportswear Clean Drinking Water Program'
                featuredImage={ planetWaterHeroImg }
                featuredAlt='A group of boys taking drinks of water'
                postContent={<>
                        <p>
                        To combat the global water crisis and improve access to clean drinking water, Columbia continues to partner with Planet Water Foundation, a U.S. non-profit organization, to build AquaTowers, or water filtration towers, in areas where CSC products are produced.
                        </p>
                        <p>
                        Our goal is to provide access to clean drinking water to 100,000 people per day by 2028. As of 2023, we have built a total of 37 AquaTowers – 11 of them in 2023, including our first water filtration tower in Guatemala, built in partnership with our supply partner, Texpasa. These efforts have helped provide up to 66,600 people with clean water each day.
                        </p>
                        <p>To learn more about what goes into a water tower build, watch the video below.</p>

                        <AspectRatio ratio={16 / 9}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/gC4QI_zAiQQ?si=mHB9uPxQ1n2epuJZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </AspectRatio>
                        </>}
            />
        </HomeLayout>

        

    );
}