import React from 'react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import BlogPost from '../../../components/sections/blogpost';
import Callout from '../../../components/sections/callout';

import collegiateOutdoorsHeroImg from '../../../components/ui/corporateresponsibility/impact/stories/collegiate-outdoor.jpg';
import uoOutdoorProgramImg from '../../../components/ui/corporateresponsibility/impact/stories/uo_outdoor_program.jpg';

export default function CollegiateOutdoor() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility | Impact - Collegiate Outdoor Recreation Program"
                description="Columbia, prAna and Mountain Hardwear are proud to support the Collegiate Outdoor Recreation Program, providing students access to the quality clothing and equipment that’s needed to get outside safely and comfortably."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <BlogPost 
                title='Collegiate Outdoor Recreation Program'
                featuredImage={ collegiateOutdoorsHeroImg }
                featuredAlt='A person making a fire'
                postContent={<>
                        <p>
                        Columbia, prAna and Mountain Hardwear are proud to support the Collegiate Outdoor Recreation Program, providing students access to the clothing and equipment that’s needed to get outside safely and comfortably. 
                        </p><p>
                        College students often lack access to quality outdoor gear due to cost, storage space or general accessibility. But by donating gear to outdoor programs at 16 universities across the country, our brands are helping the next generation of outdoor enthusiasts have meaningful experiences in nature, from climbing remote mountains to exploring the urban outdoors at a local park.
                        </p><p>
                        The outdoor recreation offices on college campuses organize hiking, skiing and climbing trips as a way to introduce students to outdoor activities that they may have considered inaccessible due to their lack of knowledge. These outdoor programs also provide opportunities to gain work experience, access to top-of-the-line gear, and the chance to build and foster lifelong friendships.
                        </p><p>
                        University of Oregon is one of the universities supported by the Collegiate Outdoor Recreation Program. Through their Outdoor Pursuits program, Oregon students have ample opportunities to test out gear through the Rental Barn, their program headquarters and gear rental facility. One back country survival course requires students to spend a night out in the woods with only a tarp, so giving students access to the proper apparel is vital in keeping them safe while out in the elements. Gear from the CSC brands are heavily utilized during this class.
                        </p><p>
                        Liam is a Junior Environmental Studies major and Political Science minor with a passion for the outdoors. Not only does he enjoy getting outside, he works to help others do the same, by leading outdoor excursions as well as working in the Rental Barn himself. Liam educates students on what kind of gear they need for any planned outdoor activity as well as ensure they have the knowledge necessary to be successful from the trail to the mountain.
                        </p><p>
                        In addition to working in the Rental Barn, Liam himself takes advantage of the services offered. He recently participated in a study abroad program where he camped for two months in New Zealand while using a Mountain Hardwear Outpost Tent that was rented through the program. The tent held up so well against the elements, Liam bought one for himself.
                        </p><p>
                        Avery, a Sophomore Geology and French major who works for the bike shop on campus, leads intro to biking classes geared toward BIPOC and women. They have found that one major barrier to entry into biking is the uncertainty of what gear is needed to be successful. But by educating participants and giving them the opportunity to test out the gear themselves, Avery and the outdoor program have helped many first-timers become regular bicyclists.
                        </p><p>
                        Sustainability is a big part of University of Oregon’s Outdoor Pursuits program. By having students rent the gear, it gets used continuously. The program also teaches students how to properly care for their outdoor gear and repair it when necessary, extending the life of the gear and keeping it out of landfills.
                        </p><p>
                        The Outdoor Pursuits program goes beyond just making gear accessible to students as they venture outdoors, often for the first time. It’s also about introducing people to activities they may have considered out of reach and encouraging them to get outside and stay outside longer, ultimately fostering a lifelong appreciation of the outdoors and the recreational opportunities it provides.
                        </p>
                        <Callout
                            media={ uoOutdoorProgramImg }
                            headline={"Learn more about UO's Outdoor Program"}
                            description={"The Outdoor Program's mission is to inspire learning through student-powered outdoor adventure."}
                            link_text={"Check them out on Instagram"}
                            link_url="https://www.instagram.com/uo_outdoorprogram/?hl=en"
                        />
                        </>}
            />
        </HomeLayout>

        

    );
}