import React from 'react'
import Helmet from 'react-helmet'

const Seo = ({title, description, links, children, ...props}) => {
    const DEFAULT_SITE_TITLE = 'Columbia Sportswear Company';
    const fullTitle = title ? `${title} | ${DEFAULT_SITE_TITLE}` : DEFAULT_SITE_TITLE;

    console.log(links);
    return (
        <Helmet {...props}>
            <title>{fullTitle}</title>
            {description && <meta name="description" content={description} />}
            {links && links.map(l => <link rel={l.rel} href={l.href} />)}
            {children}
        </Helmet>
    )
}

export default Seo
