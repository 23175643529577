import { Box, Stack, Flex } from '@chakra-ui/react';

export default function TextWithLeftMedia({ title, content, cta_text, cta_url, media, is_full_bleed }) {

  return (
    <Box
        as='section'
        className={`${ is_full_bleed ? "full-bleed" : ""}`}
    >   
      <Stack
        align="center"
        spacing={{ base: 10, md: 20 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        px={{base: 0, md: 10}}>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
          >
          { media }
        </Flex>
        <Box
            className='flow' 
            flex={1}
            px={{ base: 5, md: 0 }}
        >
          <h3>
            { title }
          </h3>
            { content }

            {cta_url ? (
            <Box 
                as='a'
                className='btn btn-black btn-normal pill-border'
                textAlign={'center'}
                href={cta_url}
                target='_blank'
                display={'inline-block'}
            >
                {cta_text}
            </Box>
            ) : (
              null
            )}
        </Box>
      </Stack>
    </Box>
  );
}
