import { Box, SimpleGrid } from '@chakra-ui/react';
import CardLink from '../cardlink';

// Get the logos
import columbiaLogo from '../../ui/brandlogos/columbia_logo.png';
import sorelLogo from '../../ui/brandlogos/sorel_logo.png';
import mhwLogo from '../../ui/brandlogos/mhw_logo.png';
import pranaLogo from '../../ui/brandlogos/prana_logo.png';

// Get the background images
import mhwBkgImg from '../../ui/globalcareers/fourbrand/mhw_bkg.jpg';
import pranaBkgImg from '../../ui/globalcareers/fourbrand/prana_bkg.jpg';
import columbiaBkgImg from '../../ui/globalcareers/fourbrand/columbia_bkg.jpg';
import sorelBkgImg from '../../ui/globalcareers/fourbrand/sorel_bkg.jpg';


export default function SimpleFourColumns() {
  return (
    <Box>
      <SimpleGrid columns={{ base: 2, lg: 4 }}
        gap={2}
        padding={{ base: 0, md: 10 }}
      >
        <CardLink
          title="Columbia logo"
          backgroundImage={columbiaBkgImg}
          href="/careers/our-brands-group"
          logo={columbiaLogo}
          logoWidth={'52%'}
        />
        <CardLink
          title="SOREL logo"
          backgroundImage={sorelBkgImg}
          href="/careers/our-brands-group?tab-9d37deaf387dfeb99f5="
          logo={sorelLogo}
          logoWidth={'40%'}
        />
        <CardLink
          title="Mountain Hardwear logo"
          backgroundImage={mhwBkgImg}
          href="/careers/our-brands-group?tab-7f9133205962d06a60c="
          logo={mhwLogo}
          logoWidth={'40%'}
        />
        <CardLink
          title="prAna logo"
          backgroundImage={pranaBkgImg}
          href="/careers/our-brands-group?tab-05cf9eb2b0103558eb1="
          logo={pranaLogo}
          logoWidth={'46%'}
        />
      </SimpleGrid>
    </Box>
  );
}
