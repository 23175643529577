import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Image, Tabs, TabList, TabPanels, TabPanel, Tab, Fade } from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout';
import OverviewTabPanel from './overviewtabpanel'
import EmpoweringPeopleTabPanel from './empoweringpeopletabpanel'
import SustainingPlacesTabPanel from './sustainingplacestabpanel'
import ResponsiblePracticesTabPanel from './responsiblepracticestabpanel'
import HighlightsTabPanel from './highlightstabpanel'

import Building from '../../components/ui/corporateresponsibility/responsiblepractices/Building.jpg';
import EmpoweringPeopleHero from '../../components/ui/corporateresponsibility/empoweringpeople/Empowering-People-UPDATED-HERO-opt.jpeg'
import Sorel from '../../components/ui/corporateresponsibility/highlights/Sorel.jpg';
import corporateresponsibilityHero from '../../components/ui/corporateresponsibility/Corporate-Responsibility-Overview-opt.jpeg';
import Outside from '../../components/ui/corporateresponsibility/sustainingplaces/Outside.png';


export default function OurCorporateResponsibilities() {
  const tabMap = useMemo(
    () =>
      new Map([
        [0, 'tab-7924f1d2e71de065b6f'], //overview
        [1, 'tab-bd5d2f27b7afd43e2d1'], //highlights
        [2, 'tab-6b450decc1cd295a70a'], //empowering people
        [3, 'tab-19017f87daea3a103ca'], //sustaining places
        [4, 'tab-b57f0800d43fff2925c'], //responsible practices
        
      ]),
    []
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = useCallback(
    index => {
      setSearchParams(tabMap.get(index), { replace: true });
      setTabIndex(index);
    },
    [tabMap, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.has(tabMap.get(0))) {
      handleTabsChange(0);
    } else if (searchParams.has(tabMap.get(1))) {
      handleTabsChange(1);
    } else if (searchParams.has(tabMap.get(2))) {
      handleTabsChange(2);
    } else if (searchParams.has(tabMap.get(3))) {
      handleTabsChange(3);
    } else if (searchParams.has(tabMap.get(4))) {
      handleTabsChange(4);
    }
  }, [tabMap, searchParams, handleTabsChange]);

  return (
    <HomeLayout align="center">
      <Box width="full">
      <Tabs
        isFitted
        isLazy
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabPanels>
          <TabPanel p={0}>
            <Fade in={true}>
              <Image
                src={corporateresponsibilityHero}
                className='page-banner'
                width="100%"
              />
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}><Image
                src={EmpoweringPeopleHero}
                className='page-banner'
                width="100%"
              /></Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}><Image
                src={Outside}
                className='page-banner'
                width="100%"
              /></Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}><Image
                src={Building}
                className='page-banner'
                width="100%"
              /></Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}><Image    
                src={Sorel} 
                className='page-banner'
                width="100%"
            /></Fade>
          </TabPanel>
        </TabPanels>

        <TabList display="flex" flexWrap="nowrap" overflowX="auto" maxWidth="100%">
          <Tab flex="1 1 0" minWidth="0"  overflow="hidden" whiteSpace="normal" >Overview</Tab>
          <Tab flex="1 1 0" minWidth="0"  overflow="hidden" whiteSpace="normal" >Highlights</Tab>
          <Tab flex="1 1 0" minWidth="0"  overflow="hidden" whiteSpace="normal" >Empowering People</Tab>
          <Tab flex="1 1 0" minWidth="0"  overflow="hidden" whiteSpace="normal" >Sustaining Places</Tab>
          <Tab flex="1 1 0" minWidth="0"  overflow="hidden" whiteSpace="normal" >Responsible Practices</Tab>      
        </TabList>

        <TabPanels>
          <TabPanel> <OverviewTabPanel /> </TabPanel>
          <TabPanel> <HighlightsTabPanel /> </TabPanel>
          <TabPanel> <EmpoweringPeopleTabPanel /> </TabPanel>
          <TabPanel> <SustainingPlacesTabPanel /> </TabPanel>
          <TabPanel> <ResponsiblePracticesTabPanel /> </TabPanel>
        </TabPanels>
      </Tabs>

      </Box>

    </HomeLayout>
  );
}
