import React, { useState } from 'react';
import { Box, Stack, IconButton, useBreakpointValue, Text, Image, Card, CardBody, Heading, Link } from '@chakra-ui/react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Slider from 'react-slick';

export default function Carousel({cards}) {
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [slider, setSlider] = useState(null);
  const top = useBreakpointValue({ base: '90%', md: '30%' });
  const side = useBreakpointValue({ base: '30%', md: '20px' });

  return (
    <Box
      position="relative"
      width="full"
      >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        ml={-6}
        onClick={() => slider?.slickPrev()}>
        <FaAngleLeft size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        mr={-6}
        onClick={() => slider?.slickNext()}>
        <FaAngleRight size="40px" />
      </IconButton>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards && cards.map((card, index) => (
          <Box
            key={index}
            position="relative">
            <Card variant="unstyled"
                  key={index}
                  position="relative"
                  px={4}
                  align="center"
                  ml={4}
                  mr={6}
                  >
              <CardBody align="center">
                <Image borderRadius={10} src={card.image} />
                <Stack mt='6' spacing='3'>
                  <Heading size='md'>{card.heading}</Heading>
                  <Text>{card.text}</Text>
                  <Link href={card.link}>{card.linktext}</Link>
                </Stack>
              </CardBody>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
