import React from 'react';
import {
    Flex,
    useBreakpointValue,
    VStack,
    Stack,
    Heading,
    Container,
    Image,
    SimpleGrid,
    Text,
    LinkBox,
    LinkOverlay,
    Button,
    Icon
  } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Seo from '../../components/sections/seo';
import ReactPlayer from 'react-player/youtube';
import HomeLayout from '../../components/layouts/homelayout';
import HeadingWithText from '../../components/sections/headingwithtext'

import HeroWithRightMedia from '../../components/sections/herowithrightmedia'
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia'
import HeroWithTopMedia from '../../components/sections/herowithtopmedia'

import DCCareersHeroImg from '../../components/ui/globalcareers/DCHero.jpg'
import maskOneImg from '../../components/ui/globalcareers/Mask1.jpg'
import maskTwoImg from '../../components/ui/globalcareers/Masked2-1.jpg'
import maskThreeImg from '../../components/ui/globalcareers/Masked3-1.jpg'
import dcBikeImg from '../../components/ui/globalcareers/DCBike.png'
//import dcBikeTwoImg from '../../components/ui/globalcareers/Capture-1.jpg'
import distributionCta from '../../components/ui/globalcareers/distribution-cta.jpg'

export default function Distribution() {
    return (
        <HomeLayout>
            <Seo
                title="Careers in Distribution"
                description="Find your next career at Columbia Sportswear Company in distribution."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Flex
                className='page-banner'
                w={'full'}
                h={'60vh'}
                backgroundImage={DCCareersHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="levInnovationHeroImg"
                title={`Join our Distribution`}
            >
                <VStack
                    w={'full'}
                    justify={'flex-end'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={6} px={4}>
                        <Heading
                            align="left"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            mb="16px"
                            id="responsiblepracticestext"
                            as={'h1'}
                            >
                            Find your next adventure.<br />Explore Distribution Center jobs
                        </Heading>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW="6xl" my={14} className='flow'>
               <HeadingWithText
                    justify='center'
                    align='center'
                    title={"What We're Looking For"}
                    subTitle={"People who take pride in hard work, care about each other, and do their best every day. People like you."}
               />
               <SimpleGrid align={'center'} columns={{ base: 1, md: 2, lg: 3 }} spacing={12} mb={16}>
                    <HeroWithTopMedia
                        media={<Image width="100%" borderRadius={10} src={maskOneImg} />}
                        subTitle={`“People here work really well together and want each other to be successful. If we are successful, the company is.”`}
                    />
                    <HeroWithTopMedia
                        media={<Image width="100%" borderRadius={10} src={maskTwoImg} />}
                        subTitle={`“People here have a lot of drive and energy. They know what they want and they’re going to work hard to get it.”`}
                    />
                    <HeroWithTopMedia
                        media={<Image width="100%" borderRadius={10} src={maskThreeImg} />}
                        subTitle={`“Everybody here is a hard worker. We are always helping each other out.”`}
                    />
               </SimpleGrid>
               <HeadingWithText
                    justify='center'
                    align='center'
                    title={"Benefits"}
                    subTitle={"Benefits that won’t break the bank and take care of your family. Ask our team."}
               />
               <HeroWithLeftMedia
                    subTitle={`“The amount of PTO that we have is fantastic. Having that time available to stay with family is really important.”\n\n“Columbia does things for their employees to show, ‘Hey, we’re here for you.'”\n\n“The benefits are really important and Columbia has one of the best benefit packages I’ve been offered.”`}
                    media={<Image borderRadius={10} src={dcBikeImg} />}
               />
               {/*<Heading as={'h3'} textAlign={'center'}>
                    Behind the Scenes in Our Distribution Center
                </Heading>
                <HeroWithLeftMedia
                    subTitle={`While a lot has changed since COVID-19, there’s one thing that remains the same: our focus on safety. We make sure that everyone in our family of co-workers leaves as they came.\n\nOur distribution centers and employees play a critical role in the success of our business. The health and safety of our employees remain a top priority. As part of our response to COVID-19, we have implemented a variety of measures to ensure our distribution employees stay safe.`} 
                    media={<Image borderRadius={10} src={dcBikeTwoImg} />}
                        />
                <HeadingWithText
                    title={"Yes, There's a Columbia Sportswear Discount"}
                    subTitle={"Employees take 30% off the listed price in our retail stores and 40-50% off regular retail pricing when shopping online."}
               />*/}
               <HeroWithRightMedia
                    title={'A Day in the Life Of'}
                    subTitle={`See what a day in our distribution center is really like. We are much more than a leading global footwear and apparel company: we are a family (and we work like one).\n\nOur own Alonzo Plater will give you a look behind the scenes in one of our locations and the global supply chain that powers Columbia Sportswear.`}
                    media={<ReactPlayer url="https://www.youtube.com/embed/pV5V9MfRWEc?autoplay=0&rel=0&enablejsapi=1&wmode=opaque" />}
               />
               <Stack my={10}>
                    <Flex 
                        minH={'375px'}
                        justify="center"
                        align="center"
                        bgImage={`linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.1)) , url(${distributionCta})`}
                        bgSize="cover"
                        bgRepeat="no-repeat"
                        borderRadius={10}
                        flexDir={'column'}
                    >
                        <Text
                            whiteSpace="pre-line"
                            align="center"
                            color="white"
                            fontWeight="bold"
                            fontFamily="GerTT"
                            fontSize="4xl"
                            opacity={1.0}
                            textTransform={'uppercase'}
                            sx={{'--flow-lh': 'lineHeights.10'}}
                            py={3}>Check Out Distribution Center Jobs
                        </Text>
                        <LinkBox as={'a'}>
                            <LinkOverlay
                                href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers'
                                isExternal
                                >
                                    <Button
                                        size="md"
                                        p={6}
                                        bg="black"
                                        color="white"
                                        borderRadius={8}
                                        mt={8}
                                        textTransform={'uppercase'}
                                        >
                                        Join the Team
                                        <Icon
                                            color="white"
                                            w={5}
                                            h={5}
                                            as={ExternalLinkIcon}
                                            pl="4px"
                                            ml={1}
                                        >
                                        </Icon>
                                    </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </Flex>
                </Stack>
            </Container>
        </HomeLayout>
    );
}