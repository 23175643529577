import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';

export default function HeadingWithText({title, subTitle, ...props}) {
  return (
    <VStack>
      <Box {...props}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}
        >
          {title}
        </Heading>
        <Text fontSize="md" whiteSpace="pre-line">{subTitle}</Text>
      </Box>
    </VStack>
  )
}
