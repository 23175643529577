import { Flex, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';

export default function GridCard ({ title, backgroundImage, href, type, isExternal }) {
  return (
    <Stack>
      <LinkBox textDecoration={'none'}>
        <LinkOverlay href={href} textDecoration={'none'} isExternal>
          <Flex //h={{base: '246px', md: '220px', lg: '192px' }}
                //w="350px"
                maxW="100%"
                minH="200px"
                justify="center"
                align="center"
                bgImage={`linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)) , url(${backgroundImage})`}
                bgSize="cover"
                bgRepeat="no-repeat"
                //borderRadius={10}
                className={type}
          >
            <Text
              whiteSpace="pre-line"
              align="center"
              color="white"
              //textTransform="uppercase"
              fontWeight="bold"
              fontFamily="GerTT"
              fontSize="xl"
              opacity={1.0}>{title}</Text>
          </Flex>
        </LinkOverlay>
      </LinkBox>
    </Stack>
  );
};
