import React from 'react';
import {
    Flex,
    useBreakpointValue,
    VStack,
    Stack,
    Heading,
    Container,
    Text,
    SimpleGrid,
    LinkBox,
    LinkOverlay,
    Button
  } from '@chakra-ui/react';
import HomeLayout from '../../components/layouts/homelayout';
import GridCard from '../../components/sections/gridcard';
import Seo from '../../components/sections/seo';

import ChinaHeroImg from '../../components/ui/globalcareers/china/China-Hero.jpg'
import OfficeImg from '../../components/ui/globalcareers/china/OFFICE职位-1-1-1200x800.jpg'
import StoreImg from '../../components/ui/globalcareers/china/STORE店铺职位-1-1-1200x788.jpg'
import ChinaFooterHeroImg from '../../components/ui/globalcareers/china/China-Hero-Bottom-3.jpg'

export default function China() {
    return (
        <HomeLayout>
            <Seo
                title="Careers in China"
                description="Find your next career at Columbia Sportswear Company in China."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Flex
                className='page-banner'
                w={'full'}
                h={'60vh'}
                backgroundImage={ChinaHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="chinaHeroImg"
                title={`China`}
            >
                <VStack
                    w={'full'}
                    justify={'center'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={4} px={4}>
                        <Heading
                            align="center"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            id="chinatext"
                            >
                            We connect active people with their passions
                        </Heading>
                        <LinkBox as="a" textAlign='center'>
                            <LinkOverlay href="https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers" isExternal>
                                <Button
                                    bg="black"
                                    color="white"
                                    textTransform='uppercase'
                                    _hover={{ bg: 'gray.500' }}>
                                    加入我们
                                </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW="6xl" my={16} className='flow'>
               <Heading textAlign={'center'}>
                    Careers in China 职业机会
               </Heading>
               <SimpleGrid align={'center'} columns={{ base: 1, md: 2 }} spacing={12} sx={{'--flow-space': 'space.8'}}>
                    <GridCard
                        title={'Office 职位'}
                        href={'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers'}
                        backgroundImage={OfficeImg}
                        type="is-tall"
                        />
                    <GridCard
                        title={'Store 店铺职位'}
                        href={'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers'}
                        backgroundImage={StoreImg}
                        type="is-tall"
                        />
               </SimpleGrid>
            </Container>
            <Flex
                w={'full'}
                h={'60vh'}
                backgroundImage={ChinaFooterHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="chinafooterimg"
                title={`China`}
            >
                <VStack
                    w={'full'}
                    justify={'center'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={6} px={4}>
                        <Heading
                            align="center"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            mb="16px"
                            >
                            关于我们
                        </Heading>
                        <Text
                            color={'white'}
                            textAlign={'center'}
                            textTransform={'uppercase'}>
                            Columbia 成立于1938年，源自美国俄勒冈州波特兰市，是有着80年悠久历史的国际户外品牌。Columbia 执着于让消费者在户外得到专业的真实保护，可以更长时间地享受户外乐趣！
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
        </HomeLayout>
    );
}