import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    fonts: {
        heading: 'GerTT, sans-serif',
        body: 'Open Sans'
      },
      components: {
        Heading: {
          baseStyle: {
            fontSize: 'var(--step-2)'
          },
          variants: {
            h2: {
                fontSize: 'var(--step-2)',
            },
            h6: {
                fontSize: 'var(--step--1)',
                mb: '2px',
            }
          }
        }
      },
    styles: { 
        global: {
            ':root':{
                '--flow-space': 'var(--chakra-space-5)',
                '--flow-lh': 'var(--chakra-lineHeights-7)',
                '--site-header-height': '68px',
                '--nav-font-size': 'var(--step--1)',
                '--nav-footer-font-size': 'var(--step--2)',
            },
            '.flow > * + *': {
                mt: 'var(--flow-space)',
            },
            'h1, h2, h3, h4': {
                '--flow-space': 'var(--chakra-space-10)',
            },
            h3: {
                fontSize: 'var(--chakra-fontSizes-2xl)',
            },
            a: {
                textDecoration: 'underline',
            },
            '.flow': {
                p: {
                    lineHeight: 'var(--flow-lh)',
                },
                'h2 + p': {
                    '--flow-space': 'var(--chakra-space-2)',
                },
                'h3 + p': {
                    '--flow-space': 'var(--chakra-space-1)',
                },
                'h2 + h3': {
                    '--flow-space': 'var(--chakra-space-3)',
                },
                'h2 + .chakra-container': {
                    '--flow-space': '0',
                },
            },
            '.is-tall': {
                height:'350px',
            },
            '.is-medium': {
                height:'414px',
                width:'552px'
            },
            '.two-column': {
                height: ['300px', '330px', '414px'],
                width: ['400px', '440px', '552px']
            },
            '.three-column': {
                height: ['300px', '330px', '271px'],
                width: ['400px', '440px', '362px']
            },
            '.site-nav, .site-nav__child, .mobile-nav': {
                'list-style': 'none',
            },
            '.careers .chakra-popover__popper .chakra-popover__content .site-nav__sub': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            },
            'site-nav__link': {
                fontSize: '8px',
            },
            '.page-banner': {
                mt: 'var(--site-header-height)',
            },
            '.footer-navList': {
                listStyle: 'none',
            },
            '.footer-nav__link, .footer-copyright': {
                fontSize: 'var(--nav-footer-font-size)',
            },
            '.social-button, .footer-nav__link': {
                _hover: 
                    {textDecoration: 'none',
                    color: '#0693e3'}
                  
            },
            '.pill-border': {
                'border-radius': 'calc(infinity * 1px)',
              }
        }
      }
})

export default theme