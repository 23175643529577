import React from 'react';
import {
    Image,
    Flex,
    useBreakpointValue,
    VStack,
    Text,
    Stack,
    Heading,
    Container,
    Button,
    LinkOverlay,
    LinkBox,
  } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

import HeroWithRightMedia from '../../../components/sections/herowithrightmedia';
import ReactPlayer from 'react-player/youtube';
import HeroWithLeftMedia from '../../../components/sections/herowithleftmedia';

import levInnovationHeroImg from '../../../components/ui/corporateresponsibility/responsiblepractices/leveraginginnovation/Responsible-Practices-Leveraging-Innovation-Hero-Image.jpg'
import pranaInnovationImg from '../../../components/ui/corporateresponsibility/responsiblepractices/leveraginginnovation/prana-innovation.png'
import mhwInnovationImg from '../../../components/ui/corporateresponsibility/responsiblepractices/leveraginginnovation/mhw-innovation.jpg'

export default function SupplyChain() {
    return (
        <HomeLayout>
            <Seo
                title="Corporate Responsibility - Leveraging Innovation"
                description="Driving our core competencies of innovation and design across our portfolio of brands to create social and environmental value. "
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Flex
                w={'full'}
                h={'60vh'}
                className='page-banner'
                backgroundImage={levInnovationHeroImg}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                id="levInnovationHeroImg"
                title={`Leveraging Innovation`}
            >
                <VStack
                    w={'full'}
                    justify={'flex-end'}
                    align={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
                    >
                    <Stack w={'full'} maxW={'6xl'} spacing={6} px={4}>
                        <Heading
                            align="right"
                            color={'white'}
                            fontWeight={600}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '2xl',
                                md: '4xl',
                                sm: '3xl',
                            })}
                            mb="16px"
                            id="responsiblepracticestext"
                            >
                                <Text as="span" 
                                    fontSize={useBreakpointValue({
                                        base: 'xl',
                                        md: '3xl',
                                    })}
                                    >
                                    Responsible Practices: <br />
                                </Text>
                            Leveraging Innovation and Design to Create Social and Environmental Value
                        </Heading>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW="6xl" my={9} className='flow'>
                <Text>
                    Driving our core competencies of innovation and design across our portfolio of brands to create social and environmental value. In doing this, we aim to increase consumer loyalty and develop a strong connection to our brands.
                </Text>
                <Heading as="h2">
                    Featured Initiatives
                </Heading>             
                <HeroWithLeftMedia
                    media={<ReactPlayer url="https://www.youtube.com/embed/khorCFA-cn8?autoplay=0&rel=0&enablejsapi=1&wmode=opaque" />} 
                    title={`Columbia — ECO Jacket`} 
                    subTitle="The OutDry™ Extreme ECO Collection is Columbia’s first waterproof-breathable rainwear made with no intentionally added PFCs."
                    cta={<LinkBox as="a"><LinkOverlay href="http://blog.columbia.com/preview-outdry-extreme-eco/" isExternal><Button size='md' p={6} bg={'#808285'} color={'white'} borderRadius={'md'} _hover={{ bg: 'gray.400', color: 'black' }}>LEARN MORE</Button></LinkOverlay></LinkBox>} 
                    /> 
                <HeroWithRightMedia 
                    media={<Image borderRadius={'md'} src={pranaInnovationImg} />} 
                    title={`prAna — Clothing for Positive Change`} 
                    subTitle="prAna is propelling the sustainable clothing movement forward. When you choose to invest in a piece of prAna clothing, you are casting a vote for social and environmental responsibility, for transparency, and for a fundamental change in the way clothing is made."
                    cta={<LinkBox as="a"><LinkOverlay href="https://www.prana.com/sustainability.html" isExternal><Button size='md' p={6} bg={'#808285'} color={'white'} borderRadius={'md'} _hover={{ bg: 'gray.400', color: 'black' }}>LEARN MORE</Button></LinkOverlay></LinkBox>} 
                    />
                <HeroWithLeftMedia 
                    media={<Image borderRadius={'md'} src={mhwInnovationImg} />}
                    title={`Mountain Hardwear — Lamina ECO Sleeping Bag`} 
                    subTitle="Mountain Hardwear has set out to prove that sustainability is about opportunity, not sacrifice. But there’s more to this sleeping bag than a little green here and there. In fact, there’s no color at all. The Lamina Eco AF™ is undyed to reduce our water footprint. And on top of that, it’s made with a recycled shell, lining, insulation, and trims. ‘Eco’ alone wouldn’t quite do it justice."
                    cta={<LinkBox as="a"><LinkOverlay href="http://www.mountainhardwear.com/lamina-eco-30f-sleeping-bag-1863971.html" isExternal><Button size='md' p={6} bg={'#808285'} color={'white'} borderRadius={'md'} _hover={{ bg: 'gray.400', color: 'black' }}>LEARN MORE</Button></LinkOverlay></LinkBox>} 
                    />
            </Container>
        </HomeLayout>
    );
}