import React from 'react';
import { Heading, Text, VStack,  Container, } from '@chakra-ui/react';

export default function TextBox({ title, subTitle, textArea, ...props }) {
  return (
    <Container maxW="6xl">
      <VStack
        align="left"
        py={4}
        direction={{ base: 'column', md: 'row' }}
      >
        <Heading
          fontSize={"xl"}
          fontWeight={600}
          align="left"
        >
          {title}
        </Heading>
        <Text
          fontSize={"l"}
          fontWeight={600}
          align="left"
        >
          {subTitle}
        </Text>
        <Text fontSize="sm">{textArea}</Text>
      </VStack>
    </Container>
  );
}
