import React from 'react';
import { Heading, Box, Container, Link, Image } from '@chakra-ui/react';
import Seo from '../../components/sections/seo';

import ReactPlayer from 'react-player/youtube';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';
import HeroWithRightMedia from '../../components/sections/herowithrightmedia';

import RiseImg from '../../components/ui/corporateresponsibility/highlights/rise.jpg'
import LWGImg from '../../components/ui/corporateresponsibility/highlights/sorel_lwg.jpg'

export default function Highlights () {
    return (
        <Box>
            <Seo
                title="Corporate Responsibility Highlights"
                description="Key highlights of our commitment to Corporate Rsponsibility."
                //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
            />
            <Container maxW='6xl' my={14} className='flow'>
                <Heading as={'h1'} mt={5}>Highlights</Heading>
                <HeroWithRightMedia
                    media={<Image width="100%" borderRadius={10} src={RiseImg} />} 
                    title={<Heading fontSize="3xl"> RISE + COLUMBIA SPORTSWEAR </Heading>} 
                    subTitle={<>
                            Since 2008, we have partnered with BSR’s HERproject to empower women and men in our supply chain through workplace training programs. In 2023, HERproject joined RISE: Reimagining Industry to Support Equality, an initiative to support collaborative industry action at scale to advance gender equality in global garment, footwear, and home textiles supply chains. HERproject programs are now carried forward under RISE.​
                            <br /><br />
                            In 2019, we made a public commitment through the United Nations Foundation to scale women’s health and wellbeing programs in our supply chain to impact the lives of 150,000 workers by the end of 2025. Our partnership with RISE helps to scale health and wellbeing programs to positively impact people across our supply chain.​
                            <br /><br />
                            To learn more about our partnership with RISE, please <Link href='https://riseequal.org/' isExternal>click here</Link>. 
                    </>}
                />
                <HeroWithLeftMedia
                    media={<ReactPlayer url="https://www.youtube.com/watch?v=cDW_eHKoLwI" />}
                    title={<Heading fontSize="3xl"> PLANET WATER FOUNDATION </Heading>}               
                    subTitle={`A healthy planet needs clean water. That’s why we partner with Planet Water Foundation, a U.S. non-profit organization that helps combat the global water crisis by providing access to clean water. Over the course of our partnership, we have provided access to clean water to an estimated 46,000 people in communities across our supply chain. Additionally, in 2022, we proudly exceeded our goal to support 25 AquaTower water filtration systems by 2022. Since 2016, we have supported the deployment of 26 AquaTowers.
                    
                        Through our ongoing partnership with Planet Water Foundation, we have set a new goal to provide access to clean drinking water to 100,000 people by 2028.`}/>
                <HeroWithRightMedia
                    media={<Image width="100%" borderRadius={10} src={LWGImg} />}
                    title={<Heading fontSize="3xl">LEATHER WORKING GROUP</Heading>} 
                    subTitle={`The Leather Working Group (LWG) is committed to driving improvement across the global leather supply chain, minimizing the environmental impact of leather production, as well as inspiring, educating, and challenging its member companies. CSC has been a member of the LWG since 2021. As an LWG member, CSC prioritizes the selection of LWG certified partners when selecting new manufacturing partners in an effort to advance chemical management best practices in leather processing facilities.`}
                />
            </Container>
        </Box>
    );
}