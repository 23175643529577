import React, { useEffect, useState } from 'react';
import { Box, Stack, IconButton, useBreakpointValue, Text, Image, Card, CardBody, Heading, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Slider from 'react-slick';
import EinsteinAPI from '../../../api/einstein'

export default function Recos({siteId, title}) {
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [recos, setRecos] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const einsteinAPI = new EinsteinAPI(siteId);
      const response = await einsteinAPI.getRecommendations();
      setRecos(response?.recs);
    }
    fetchData();
  }, [siteId]);

  const [slider, setSlider] = useState(null);
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  return (
    <Box
      position="relative"
      height="600px"
      width="full"
      overflow="hidden">
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <FaAngleLeft size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <FaAngleRight size="40px" />
      </IconButton>
      <Heading align="center" p={5}>{title}</Heading>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {recos && recos.map((reco, index) => (
          <Box
            key={index}
            position="relative">
            <Card variant="unstyled">
              <CardBody>
                <LinkBox>
                  <LinkOverlay href={reco.product_url} isExternal>
                    <Stack align="center">
                      <Image src={reco.image_url}/>
                      <Text>{reco.product_name}</Text>
                    </Stack>
                  </LinkOverlay>
                </LinkBox>
              </CardBody>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
