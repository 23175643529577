import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  fonts: {
    heading: 'GerTT, sans-serif',
    body: 'Open Sans'
  },
  components: {
    Heading: {
      baseStyle: {
        fontSize: 'var(--step-2)',
      }
    }
  }
}

const theme = extendTheme({ config })

export default theme
