import React from 'react';
import { Flex } from '@chakra-ui/react';
import Header from '../../sections/header';
import Footer from '../../sections/footer';

function HomeLayout(props) {
  return (
    <Flex direction="column" {...props}>
      <Header />
      {props.children}
      <Footer />
    </Flex>
  );
}

export default HomeLayout;
