import React from 'react';
import {
  Text,
  Center,
  Container,
  Heading,
} from '@chakra-ui/react';
import HomeLayout from '../../components/layouts/homelayout'
import Seo from '../../components/sections/seo';

export default function OurHistory() {
  return (
    <HomeLayout align="left">
      <Seo
        title="Our History"
        description="Founded in 1938, Columbia Sportswear Company is a global leader in designing, sourcing, marketing, and distributing outdoor and active lifestyle apparel, footwear, accessories, and equipment."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='6xl' my={14} mt={'120px'} className='flow'>
        <Heading
          as={'h1'}
          id="historyTitle"
        >
          OUR HISTORY
        </Heading>
        <Text ml="150px" mr="150px" py={4} id="historyText">
          Founded in 1938, Columbia Sportswear Company is a global leader in
          designing, sourcing, marketing, and distributing outdoor and active
          lifestyle apparel, footwear, accessories, and equipment. Our four
          primary brands — Columbia®, Mountain Hardwear®, SOREL® and prAna® —
          complement each other to address the diverse needs of active consumers
          and their products have gained an international reputation for their
          innovation, quality and performance.
        </Text>
        <Center>
          <iframe
            src="https://www.youtube.com/embed/MPr9Tl0JQ5U?autoplay=0&rel=0&enablejsapi=1&wmode=opaque"
            allowFullScreen
            title="YouTube video player 1"
            width="900"
            height="504"
            id="columbiastoryvideo"
          ></iframe>
        </Center>
      </Container>
    </HomeLayout>
  );
}
