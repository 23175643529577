import React from 'react';
import {
  Text,
  Image,
  UnorderedList,
  ListItem,
  Stack,
  Box,
  VStack,
  Container,
  Flex,
  useBreakpointValue,
  Heading,
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';

import HomeLayout from '../../components/layouts/homelayout';
import strategyMissionValuesHeroImage from '../../components/ui/ourcompany/strategy-mission-values-hero.png';
import coreValuesStrategy from '../../components/ui/ourcompany/coreValuesStrategy.jpeg';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';

export default function StrategyMissionValues() {
  return (
    <HomeLayout align="left">
      <Seo
        title="Strategy, Mission, & Values"
        description="Our mission, core values, and strategice priorities are how we connect active people with thier passions."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Stack w="full" className='page-banner'></Stack>
      <Flex
        w={'full'}
        h={'40vh'}
        backgroundImage={strategyMissionValuesHeroImage}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        id="strategyMissionValuesHeroImg"
      >
        <VStack
          w={'full'}
          justify={'end'}
          align={'left'}
          px={useBreakpointValue({ base: 4, md: 8 })}
          pb={12}
          bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
        >
          <Container maxW={'6xl'}>
            <Heading
              as={'h1'}
              color={'white'}>
              WE CONNECT ACTIVE PEOPLE WITH THEIR PASSIONS
            </Heading>
          </Container>
        </VStack>
      </Flex>
      <Container maxW='6xl' my={14} className='flow'>
        <Heading>
          OUR MISSION
        </Heading>
        <Text id="missionStatement">
          At Columbia Sportswear Company, we're more than just a leader in the
          global active lifestyle apparel, footwear, accessories and equipment
          industry. We connect active people with their passions.
        </Text>
        <HeroWithLeftMedia
          media={
            <Image
              borderRadius={10}
              src={coreValuesStrategy}
              id="coreValuesImg"
            />
          }
          title="OUR CORE VALUES"
          subTitle={
            <UnorderedList>
              <ListItem>Enjoy an Active Life</ListItem>
              <ListItem>Compete to Win</ListItem>
              <ListItem>Relentless Improvement</ListItem>
              <ListItem>Culture of Honesty, Respect & Trust</ListItem>
              <ListItem>
                Doing the Right Thing for Consumers, Customers, Employees, the
                Company & our Communities
              </ListItem>
            </UnorderedList>
          }
        />
        <Box>
          <Heading>
            OUR STRATEGIC PRIORITIES
          </Heading>
          <Text>
            We are committed to driving long-term sustainable profitable growth.
            Our business momentum and fortress balance sheet enable us to invest
            in our strategic priorities to:
          </Text>
          <UnorderedList py={4}>
            <ListItem>Accelerate profitable growth;</ListItem>
            <ListItem>
              Create iconic products that are differentiated, functional and
              innovative;
            </ListItem>
            <ListItem>
              Drive brand engagement through increased, focused demand creation
              investments;
            </ListItem>
            <ListItem>
              Enhance consumer experiences by investing in capabilities to
              delight and retain consumers;
            </ListItem>
            <ListItem>
              Amplify marketplace excellence with a digitally-led, omni-channel
              global distribution; and
            </ListItem>
            <ListItem>
              Empower talent that is driven by our core values through a diverse
              and inclusive workforce.
            </ListItem>
          </UnorderedList>
        </Box>
      </Container>
    </HomeLayout>
  );
}
