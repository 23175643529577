import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { Image, Tabs, TabList, TabPanels, TabPanel, Tab, Fade } from '@chakra-ui/react';

import HomeLayout from '../../../components/layouts/homelayout';
import AsiaTabPanel from './asiatabpanel'
import NATabPanel from './natabpanel'
import EuropeTabPanel from './europetabpanel'
import naBanner from '../../../components/ui/regionalcareers/NAHero.jpg'
import euBanner from '../../../components/ui/regionalcareers/Europe-Hero.jpg'
import aBanner from '../../../components/ui/regionalcareers/Asia_Hero.png'

export default function OurRegions() {
  const tabMap = useMemo(() => new Map([
    [0, "tab-c40defabe206bb77568"],
    [1, "tab-92a940378463b5a55aa"],
    [2, "tab-8a6e0fbdfb7865841e4"]
  ]), []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = useCallback((index) => {
    setSearchParams(tabMap.get(index), { replace: true });
    setTabIndex(index)
  }, [tabMap, setSearchParams]);

  useEffect(() => {
    if (searchParams.has(tabMap.get(0))) {
      handleTabsChange(0);
    } else if (searchParams.has(tabMap.get(1))) {
      handleTabsChange(1);
    } else if (searchParams.has(tabMap.get(2))) {
      handleTabsChange(2);
    } 
  }, [tabMap, searchParams, handleTabsChange])

  return (
    <HomeLayout align="center">
      <Tabs isFitted w="full" isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabPanels>
          <TabPanel p={0}>
            <Fade in={true}>
              <Image src={naBanner} className='page-banner' />
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}>
              <Image src={aBanner} className='page-banner' />
            </Fade>
          </TabPanel>
          <TabPanel p={0}>
            <Fade in={true}>
              <Image src={euBanner} className='page-banner' />
            </Fade>
          </TabPanel>
        </TabPanels>

        <TabList>
          <Tab>North America</Tab>
          <Tab>Asia</Tab>
          <Tab>Europe</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
           <NATabPanel />
          </TabPanel>
          <TabPanel>
            <AsiaTabPanel />
          </TabPanel>
          <TabPanel>
            <EuropeTabPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </HomeLayout>
  );
}
