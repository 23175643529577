const localeItems =
  {
    label: "Language",
    children: [
      {
        label: "English",
        href: "#"
      },
      {
        label: "Français",
        href: "#"
      },
      {
        label: "中国",
        href: "#"
      },
      {
        label: "日本",
        href: "#"
      }
    ]
  };

export default localeItems;
