import React from 'react';
import {
  Image,
  VStack,
  Box,
  Text,
  Container
} from '@chakra-ui/react';
import Seo from '../../components/sections/seo';

import BioWithLeftMedia from '../../components/sections/biowithleftmedia';
import BioWithRightMedia from '../../components/sections/biowithrightmedia';
import TextBox from '../../components/sections/textbox'

import chairman from '../../components/ui/ourcompany/leadership/Tim_Boyle.jpeg'
import EVPChiefAdminOfficerGeneralCounsel from '../../components/ui/ourcompany/leadership/Peter_Bragdon_Chief_Administrative_Officer_COLOR_1-copy-1.jpeg'
import EVPChiefSupplyChain from '../../components/ui/ourcompany/leadership/Lisa_Kulok_SVP_Global_Supply_Chain_Operations_COLOR.jpeg'
import EVPCorpAffairsAndHumanResources from '../../components/ui/ourcompany/leadership/richelle.jpeg'
import EVPChiefDigitalInformationOfficer from '../../components/ui/ourcompany/leadership/Skip-Potter-Headshot.jpeg'
import SVPAsiaDirect from '../../components/ui/ourcompany/leadership/Peter-Rauch.jpeg'
import EVPChiefFinancialOfficer from '../../components/ui/ourcompany/leadership/Screen-Shot-2019-08-09-at-3.18.55-PM.png'
import SVPEmergingBrands from '../../components/ui/ourcompany/leadership/20210329_SET1_CSC_CRAIG_ZANON_SVP_EMERGING_BRANDS_4_COLOR-4-scaled.jpeg'


export default function CorporateTabPanel() {
  return (
    <Box>
      <Seo
        title="Leadership at Columbia Sportswear Company"
        description="Executive leadership at Columbia Sportswear Company."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='6xl' my={14} className='flow'>
        <BioWithLeftMedia
          media={
            <Image
              borderRadius={10}
              src={chairman}
              id="timboyleimg"
            />
          }
          title={
                'TIM BOYLE'
          }
          subTitle={
            'CHAIRMAN, PRESIDENT & CHIEF EXECUTIVE OFFICER'
          }
          bio={
            <>
              <Text>
                  Tim Boyle has served as chief executive officer of Columbia
                  Sportswear Company since 1988 and reassumed the role of President
                  in 2017, which he had previously held until 2015. Tim was
                  appointed Chairman of the Board in 2020. He oversees operations of
                  the active outdoor company from its Portland, Oregon headquarters.
                </Text>
                <Text>
                  Tim’s career with Columbia Sportswear began in 1971 when, during
                  his senior year at The University of Oregon, his father, who had
                  been running the company since 1964, died suddenly of a heart
                  attack. His mother, Gert Boyle, quickly enlisted Tim’s help in
                  order to continue the aggressive expansion that her husband had
                  initiated and that had expanded the company’s sales that year to
                  $1 million.
                </Text>
                <Text>
                  An alumnus of Portland, Oregon’s Jesuit High School (1967) and the
                  University of Oregon (1971, B.S. Journalism), Tim serves on the
                  Board of Directors of Northwest Natural Gas Company.
                </Text>
                <Text>Tim is Joseph P. Boyle’s father.</Text>
              </>
          }
        />
        <BioWithRightMedia
          media={
            <Image
              borderRadius={10}
              src={EVPChiefAdminOfficerGeneralCounsel}
            />
          }
          title={'PETER BRAGDON'}
          subTitle={'EXECUTIVE VICE PRESIDENT, CHIEF ADMINISTRATIVE OFFICER AND GENERAL COUNSEL'}
          bio={
            <Text>
                Peter J. Bragdon joined Columbia Sportswear in 1999 as Senior
                Counsel and Director of Intellectual Property. He became Vice
                President and General Counsel, Secretary of Columbia in July 2004,
                was named Senior Vice President of Legal and Corporate Affairs,
                General Counsel and Secretary in January 2010, and Executive Vice
                President, Chief Administrative Officer, General Counsel, and
                Secretary in February 2015. Mr. Bragdon served as Chief of Staff
                in the Oregon Governor’s office from January 2003 through June
                2004. From 1993 to 1999, Mr. Bragdon was an attorney in the
                corporate securities and finance group at Stoel Rives LLP. Mr.
                Bragdon served as Special Assistant Attorney General for the
                Oregon Department of Justice for seven months in 1996. Mr. Bragdon
                was previously appointed by the Governor of Oregon as a
                commissioner for the Port of Portland.
            </Text>
          }
        />
        <BioWithLeftMedia
          media={
            <Image
              borderRadius={10}
              src={EVPChiefSupplyChain}
              id="supplychainofficerimg"
            />
          }
          title={'LISA KULOK'}
          subTitle={'EXECUTIVE VICE PRESIDENT, CHIEF SUPPLY CHAIN OFFICER'}
          bio={
            <Text>
                Lisa Kulok joined Columbia Sportswear in 2008 and was appointed
                Executive Vice President, Chief Supply Chain Officer in November
                2020. In 2015 Ms. Kulok was appointed Senior Vice President of
                Global Supply Chain Operations becoming Senior Vice President,
                Global Supply Chain Operations and Manufacturing in July 2020. She
                joined Columbia in February 2008 as Senior Director of Global
                Planning. From 1987 to 2007, Ms. Kulok held various leadership
                positions at NIKE, Inc., including USA Apparel Marketplace
                Planning Director and Director of Regional Planning. During this
                time, she led numerous teams that developed and implemented
                planning and supply chain processes, systems and organizations
                that supported growth of the company’s annual revenues.
              </Text>
          }
        />
        <BioWithRightMedia
          media={
            <Image
              borderRadius={10}
              src={EVPCorpAffairsAndHumanResources}
              id="corporateaffairsimg"
            />
          }
          title={'RICHELLE LUTHER'}
          subTitle={'EXECUTIVE VICE PRESIDENT, CORPORATE AFFAIRS AND CHIEF HUMAN RESOURCES OFFICER'}
          bio={    
              <Text>
                Richelle T. Luther was promoted to Executive Vice President,
                Corporate Affairs and Chief Human Resources Officer in February
                2023. She was appointed Senior Vice President & Chief Human
                Resource Officer in September 2015. She joined Columbia Sportswear
                Company in June 2008 as Deputy General Counsel. Prior to Columbia
                Sportswear Company, she served as corporate secretary and chief
                governance officer at Northwest Natural Gas (Nasdaq:NWN) from 2002
                to 2008, and was an attorney at the Portland, Oregon law offices
                of Stoel Rives LLP from 1997 to 2002.
              </Text>
            }
        />
        <BioWithLeftMedia
          media={
            <Image
              borderRadius={10}
              src={EVPChiefDigitalInformationOfficer}
              id="chiefdidgitalinformationimg"
            />
          }
          title={'SKIP POTTER'}
          subTitle={'EXECUTIVE VICE PRESIDENT, CHIEF DIGITAL INFORMATION OFFICER'}
          bio={
              <Text>
                Skip M. Potter joined Columbia Sportswear Company in April 2021 to
                assume the role of Executive Vice President, Chief Digital
                Information Officer. Before joining Columbia, Mr. Potter spent
                four years as Nike’s Chief Technology Officer. Prior to his time
                with Nike, he was the Managing Vice President of Engineering and
                Vice President of Technology Innovation at Capital One. He also
                served as the CIO/CTO for British Telecommunication’s Enterprise
                Group.
              </Text>
          }
        />
        <BioWithRightMedia
          media={
            <Image
              borderRadius={10}
              src={SVPAsiaDirect}
              id="svpAsiaDirectImg"
            />
          }
          title={'PETER RAUCH'}
          subTitle={'SENIOR VICE PRESIDENT, ASIA DIRECT'}
          bio={
              <Text>
                Peter M. Rauch joined Columbia Sportswear Company in January 2008
                as Retail Accounting Manager, was promoted to Director of Retail
                Accounting in April 2010, Senior Financial Controller, EMEA in
                June 2011, Chief Financial Officer, China in July 2013, and Vice
                President, Chief Accounting Officer in August 2016. He was
                promoted to Senior Vice President and Chief Transformation Officer
                in July 2017 and appointed Senior Vice President, Asia Direct in
                March 2020. Prior to Columbia Sportswear Company, he served as
                Senior Manager Accounting & Reporting for Webtrends from 2006 to
                2007 and was an accountant with Deloitte from 1999 to 2006. He is
                a certified public accountant.
              </Text>
          }
        />
        <BioWithLeftMedia
          media={
            <Image
              borderRadius={10}
              src={EVPChiefFinancialOfficer}
              id="chiefFinancialOfficerImg"
            />
          }
          title={'JIM SWANSON'}
          subTitle={'EXECUTIVE VICE PRESIDENT AND CHIEF FINANCIAL OFFICER'}
          bio={
              <Text>
                Jim Swanson joined Columbia Sportswear in 2003 and was appointed
                Executive Vice President and Chief Financial Officer in November
                2020. From 2017 to 2020 Mr. Swanson served as Senior Vice
                President and Chief Financial Officer. Mr. Swanson joined Columbia
                Sportswear in April 2003 as Global Senior Financial Analyst and
                has served in numerous roles of increasing responsibility during
                his tenure, being named Vice President of Finance in May 2015.
                Prior to joining Columbia, Mr. Swanson served in a variety of
                financial planning and analysis, tax, and accounting roles,
                including senior financial analyst at Freightliner Corporation and
                at Tality Corporation – a wholly-owned subsidiary of Cadence
                Design Systems, and as a senior tax and business advisory
                associate at Arthur Andersen.
              </Text>
          }
        />
        <BioWithRightMedia
          media={
            <Image
              borderRadius={10}
              src={SVPEmergingBrands}
              id="SVPEmergingBrandsImg"
            />
          }
          title={'CRAIG ZANON'}
          subTitle={'EXECUTIVE VICE PRESIDENT, EUROPE DIRECT, ASIA DIRECT AND EMERGING BRANDS'}
          bio={
              <Text>
                Craig Zanon joined Columbia Sportswear Company in April 2021 as
                Senior Vice President, Emerging Brands. Prior to joining Columbia,
                Mr. Zanon had a long career at Nike, where most recently, he
                served as Vice President and General Manager of Global Basketball.
                During his time at Nike, he also served as Vice President for U.S.
                Footwear and General Manager for the Americas.
              </Text>
          }
        />
        <VStack align={'justify-left'}>
          <TextBox
            title={'BOARD OF DIRECTORS'}
          />

          <Box>
            <TextBox
              title={'TIMOTHY BOYLE'}
              subTitle={'CHAIRMAN, PRESIDENT AND CHIEF EXECUTIVE OFFICER'}
              textArea={
                'Mr. Boyle has served on the Board since 1978 and was appointed Chairman of the Board in January 2020. Mr. Boyle joined Columbia in 1971 as General Manager, has served as Chief Executive Officer since 1988, and reassumed the role of President in 2017, which he had previously held from 1988 to 2015. Mr. Boyle is a member of the board of directors of Northwest Natural Holding Company (NYSE: NWN), and its subsidiary, Northwest Natural Gas Company, and formerly served on the board of directors of Craft Brew Alliance, Inc. Mr. Boyle is Joseph P. Boyle’s father. Mr. Boyle has spent his entire business career growing Columbia into a global leader in outdoor, active and everyday lifestyle apparel, footwear, accessories, and equipment products. Mr. Boyle’s customer relationships, market knowledge and breadth of experience performing nearly every function within Columbia has resulted in a deep understanding of the business issues facing Columbia.'
              }
            />
          </Box>

          <Box>
            <TextBox
              title={'STEPHEN E. BABSON'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Babson has served on the Board since 2002. Mr. Babson chairs the Compensation Committee. Mr. Babson is a Managing Director of Endeavour Capital, a Northwest private equity firm, which he joined in 2002. Prior to 2002, Mr. Babson was an attorney at Stoel Rives LLP. Mr. Babson joined Stoel Rives in 1978, was a partner from 1984 to 2002, and served as the firm’s chairman from 1999 to 2002. Mr. Babson serves on a number of boards of privately-held companies, including Pendleton Woolen Mills, Inc.; ATL Technology, LLC; Peninsula Holdings, LLC; and ENTEK Technology Holdings LLC. Mr. Babson brings a combination of financial and legal expertise to the Board. His experience in a private equity firm provides Columbia with valuable insights related to capital markets, strategic planning and financial integrity.'
              }
            />
          </Box>

          <Box>
            <TextBox
              title={'ANDY D. BRYANT'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Bryant has served on the Board since 2005. Mr. Bryant co-chairs the Nominating and Corporate Governance Committee and has served as Lead Independent Director since January 2020. Mr. Bryant served as Chairman of the Board of Intel Corporation (Nasdaq: INTC) from 2012 to 2020. Mr. Bryant joined Intel Corporation in 1981 and held several leadership roles, including Vice Chairman of the Board of Directors from 2011 to 2012 and Executive Vice President and Chief Administrative Officer from 2007 until 2012. Mr. Bryant is a former director of Silver Crest Acquisition Corporation and McKesson Corporation. Mr. Bryant’s years of experience at a large, global public company provide operational, strategic planning and financial expertise to the Board.'
              }
            />
          </Box>

          <Box>
            <TextBox
              title={'JOHN CULVER'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Culver has served on the Board since 2021. Mr. Culver served as Group President, North America and Chief Operating Officer of Starbucks Corporation through 2022. Mr. Culver joined Starbucks Corporation in 2002 as Vice President; General Manager, Foodservice and held various positions after, including Group President, International, Channel Development and Global Coffee, Tea & Cocoa from 2018 to 2021. Mr. Culver serves on the board of Kimberly-Clark Corporation (NYSE: KMB). Mr. Culver brings a combination of global public company and operational and strategic planning expertise to the Board.'
              }
            />
          </Box>

          <Box>
            <TextBox
              title={'KEVIN MANSELL'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Mansell has served on the Board since 2019. Mr. Mansell co-chairs the Nominating and Corporate Governance Committee. Mr. Mansell spent over 35 years at Kohl’s Corporation (NYSE: KSS), most recently serving as its Chairman, Chief Executive Officer and President prior to retiring in 2018. Mr. Mansell began his retail career in 1975 with the Venture Store Division of May Department Stores, where he held a number of positions in buying and merchandising. He joined Kohl’s Corporation in 1982 and served in several management roles, including President from 1999, Chief Executive Officer from 2008 and Chairman of the Board of Directors from 2009 until his retirement in 2018. Mr. Mansell is Chair of the Board of Directors of Chicos FAS, Inc. (NYSE: CHS) and serves as Lead Independent Director and Chair of the Compensation Committee of Fossil Group, Inc. (Nasdaq: FOSL). Mr. Mansell brings a combination of retail, public company, strategic and financial expertise to the Board.'
              }
            />
          </Box>

          <Box>
            <TextBox
              title={'RONALD E. NELSON'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Nelson has served on the Board since 2011. He joined NIKE, Inc. in 1976 and went on to serve as Vice President from 1982 to 1997, overseeing a wide variety of operations, including NIKE’s early advertising, promotions and retail operations, global footwear sourcing and financing, and the global apparel division, and he served as President of NIKE’s Japanese subsidiary from 1995 to 1997, retiring from NIKE in 1997. Mr. Nelson served as an advisory board member to Columbia in the 1970s. Mr. Nelson’s broad and deep experience within the apparel and footwear industry provides the Board with insights and guidance regarding our global supply chain, marketing and growth strategies.'
              }
            />
          </Box>
          <Box>
            <TextBox
              title={'SABRINA SIMMONS'}
              subTitle={'DIRECTOR'}
              textArea={
                'Ms. Simmons has served on the Board since 2018. She served as Executive Vice President and Chief Financial Officer of Gap, Inc. (NYSE: GPS) from 2008 until 2017. Previously, Ms. Simmons also served in the following positions at Gap: Executive Vice President, Corporate Finance from 2007 to 2008, Senior Vice President, Corporate Finance and Treasurer from 2003 to 2007, and Vice President and Treasurer from 2001 to 2003. Prior to that, Ms. Simmons served as Chief Financial Officer and an executive member of the board of directors of Sygen International PLC, and was Assistant Treasurer at Levi Strauss & Co. Ms. Simmons currently serves as a member of the board of directors and chair of the audit committee of each of Coursera, Inc. (NYSE: COUR) and Petco Health and Wellness Company, Inc. (Nasdaq: WOOF). Ms. Simmons formerly served on the board of e.l.f. Beauty, Inc. and Williams-Sonoma, Inc. Ms. Simmons brings a combination of public company, global retail and financial experience to the Board.'
              }
            />
          </Box>
          <Box>
            <TextBox
              title={'CHRISTIANA SMITH SHI'}
              subTitle={'DIRECTOR'}
              textArea={
                'Ms. Shi has served on the Board since 2022. Ms. Shi is Principal at Lovejoy Advisors, LLC, an advisory services firm focused on digitally transforming consumer and retail businesses, which she founded in 2016. Ms. Shi joined Nike, Inc. in 2010 and most recently served as President, Direct-to-Consumer from 2013 until her retirement in 2016. Prior to that, Ms. Shi spent 24 years at McKinsey & Company in various roles, including Director and Senior Partner from 2000 to 2010. Ms. Shi began her career at Merrill Lynch & Company in 1981. Ms. Shi currently serves on the Board of Directors of Mondelēz International, Inc. (Nasdaq: MDLZ) and United Parcel Service, Inc. (NYSE: UPS). She served on the Boards of Directors of West Marine, Inc. until 2017 and Williams-Sonoma, Inc. until 2019.'
              }
            />
          </Box>
          <Box>
            <TextBox
              title={'MALIA H. WASSON'}
              subTitle={'DIRECTOR'}
              textArea={
                'Ms. Wasson has served on the Board since 2015. Ms. Wasson chairs the Audit Committee, and the Board has designated Ms. Wasson as an “audit committee financial expert.” Ms. Wasson worked at U.S. Bank of Oregon for over 25 years, serving as President of U.S. Bank’s Oregon and Southwest Washington operations from 2005 to 2015. In addition to her role as President, she led the Oregon Commercial Banking group for U.S. Bank, which provides a wide variety of financial services to middle market companies. Currently, Ms. Wasson is the Chief Executive Officer of Sand Creek Advisors LLC, which provides business consulting to CEOs of public and private companies. Ms. Wasson serves as Chair of the board of directors and as a member of the governance committee of Northwest Natural Holding Company (NYSE: NWN), as well as Chair of the board of directors of its subsidiary, Northwest Natural Gas Company. Ms. Wasson’s extensive experience in commercial banking, finance and accounting, as well as local and regional leadership, enables her to provide insight and advice to Columbia on strategic matters including mergers and acquisitions, consumer and commercial businesses, regulatory, marketing, public and government policy and relations, media relations, change management and human capital management and diversity.'
              }
            />
          </Box>
          <Box>
            <TextBox
              title={'CHARLES DENSON'}
              subTitle={'DIRECTOR'}
              textArea={
                'Mr. Denson is the Chairman of the Board of Directors of Funko, Inc., where he has served as a director since its formation in 2017, in addition to serving as a director of FAH, LLC since 2016. Mr. Denson has served as the President and Chief Executive Officer of Anini Vista Advisors, an advisory and consulting firm, since 2014. From 1979 to 2014, he held various positions at NIKE, Inc., where he was appointed to several management roles, including President of the NIKE Brand, a position he held from 2001 to 2014.'
              }
            />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}
