const footerNavLegal = [
    {
        label: "Privacy Policy",
        href: "https://cscworkday.blob.core.windows.net/legal/policies/Applicant_and_Employee_Privacy_Notice.pdf",
        isExternal: true
    },
    {
        label: "Cookie Notice",
        href: "https://cscworkday.blob.core.windows.net/legal/policies/Cookie_Notice_Corporate_and_Recruiting_Site.pdf",
        isExternal: true
    },
    {
        label: "Terms of Use",
        href: "/termsofuse"
    },
    {
        label: "Transparency in Supply Chain Statement",
        href: "https://cscworkday.blob.core.windows.net/hrforms/Recruiting/Career_Site/Supply_Chain/Transparency_in_Supply_Chain_Statement.pdf",
        isExternal: true
    }
];

export default footerNavLegal;