// Get the logos
import columbiaLogo from '../../ui/brandlogos/columbia_logo.png';
import sorelLogo from '../../ui/brandlogos/sorel_logo.png';
import mhwLogo from '../../ui/brandlogos/mhw_logo.png';
import pranaLogo from '../../ui/brandlogos/prana_logo.png';

export default function BrandLockup() {
    return(
        <div className='brand-lockup'>
            <div className='brand-lockup__logo'>
                <img id='brand-lockup__columbia' src={columbiaLogo} alt='Columbia Sportswear logo' />
            </div>
            <div className='brand-lockup__logo'>
                <img id='brand-lockup__sorel' src={sorelLogo} alt='SOREL logo' />
            </div>
            <div className='brand-lockup__logo'>
                <img id='brand-lockup__mhw' src={mhwLogo} alt='Mountain Hardwear logo' />
            </div>
            <div className='brand-lockup__logo'>
                <img id='brand-lockup__prana' src={pranaLogo} alt='prAna logo' />
            </div>
        </div>
    );
}