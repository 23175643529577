import React from 'react';
import { Image, Box, Text, SimpleGrid, Container, Heading } from '@chakra-ui/react';
import Seo from '../../components/sections/seo';

import QuoteWithLeftMedia from '../../components/sections/quotewithleftmedia';
import QuoteWithRightMedia from '../../components/sections/quotewithrightmedia';

import timBoyle from '../../components/ui/corporateresponsibility/timBoyle.jpeg'
import abelNavarrete from '../../components/ui/corporateresponsibility/abelNavarrete.png'
import GridCard from '../../components/sections/gridcard';

import mountainHardwearCard from '../../components/ui/corporateresponsibility/2021_02_MHW_S21_day5-2488.jpeg'
import columbiaCard from '../../components/ui/corporateresponsibility/2021-02_Columbia_TK_F21_Titanium-2497.jpeg'
import pranaCard from '../../components/ui/corporateresponsibility/Image-9-opt.jpeg'
import sorelCard from '../../components/ui/corporateresponsibility/Sorel-W20_Tivoli_IV_Can_31782_R1.jpeg'

import CtaShort from '../../components/sections/ctashort';
import impactReportCtaImg from '../../components/ui/corporateresponsibility/cta_impact_overview.png';

export default function OverviewTabPanel() {
  return (
    <Box>
      <Seo
        title="Corporate Responsibility Overview"
        description="We invest in initiatives that have a positive impact on the people we reach, the places we touch, and the products we make."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='6xl' my={14} className='flow'>
        <Text as={'h1'} fontSize={'3xl'} fontWeight={600} py={4} sx={{'--flow-lh': 'lineHeights.10'}} fontFamily={'GerTT'}>
          AT COLUMBIA SPORTSWEAR COMPANY, WE INVEST IN INITIATIVES THAT HAVE A
          POSITIVE IMPACT ON THE PEOPLE WE REACH, THE PLACES WE TOUCH, AND THE
          PRODUCTS WE MAKE.
        </Text>
        <QuoteWithLeftMedia
            media={<Image borderRadius={10} src={timBoyle} id="timBoyleImg" />}
            speaker="– Tim Boyle, Chairman, President, & CEO"
            quote={<>“All of us want to have an impact. Every day, we make choices as to whether that impact is good or bad. At Columbia Sportswear Company, one of our core values is doing the right thing.​
              Every day, we choose to do the right thing so that our impact on our people and the planet​ is positive.
              <br /><br />
              My mother, Gert Boyle, the former Chair of our Board, used to say, 'It’s perfect. Now make it​ better.' That kind of thinking undergirds our approach to environmental, social and governance​ challenges and the impact that we have on the world. There is always room to make something better. And we are constantly evolving in our use of technologies, practices and policies to ensure that we continue to get better.” 
              </>
            }
        />
        <QuoteWithRightMedia
            media={<Image borderRadius={10} src={abelNavarrete} id="abelNavarrete" />}
            speaker="– Abel Navarrete, VP, Sustainability and Community Impact"
            quote={`"Our Corporate Responsibility team works to ensure we’re doing our part to make the outdoors accessible to all. From working with community partners to supporting programs and initiatives in the places that our products are made, we’re committed to responsible practices throughout our business."`}
        />
        
        <Box
          as='section'
        >
          <Heading
            mb={'var(--space-s)'}>
            IMPACT REPORTS & DISCLOSURES
          </Heading>
          <CtaShort 
              button_text={'Read the Impact Reports'}
              button_url={'/corporate-responsibility/impact'}
              backgroundImage={impactReportCtaImg}
            />

        </Box>
            
        <Heading>
          OUR BRAND'S INITIATIVES
        </Heading>
        <SimpleGrid align={'center'} columns={{base: 1, md: 2}} spacing={6} py={4}>
          <GridCard
            title="COLUMBIA"
            href="https://www.columbia.com/corporate-responsibility/"
            backgroundImage={columbiaCard}
            type='is-tall'
          />
          <GridCard
            title="PRANA"
            href="https://www.prana.com/sustainability.html"
            backgroundImage={pranaCard}
            type='is-tall'
          />
          <GridCard
            title="MOUNTAIN HARDWEAR"
            href="https://www.mountainhardwear.com/sustainability.html"
            backgroundImage={mountainHardwearCard}
            type='is-tall'
          />
          <GridCard
            title="SOREL"
            href="https://www.sorel.com/about-us_responsibility/"
            backgroundImage={sorelCard}
            type='is-tall'
          />
        </SimpleGrid>
      </Container>
      </Box>
  );
}
