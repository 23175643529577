import React from 'react';
import { Heading, Stack, Box, Text } from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout';

function TermsOfUse() {
  return (
    <HomeLayout>
      <Stack pt={100} px={40}>
        <Heading align="center">TERMS OF USE</Heading>
        <Box align="left" p={10} fontSize="sm">
          <Text fontWeight="bold">Date of Last Revision: 07/19/2019</Text>
          <Text whiteSpace="pre-line">
            {`\nPLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY ACCESSING OR USING THIS WEB SITE OR MOBILE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS WEB SITE OR MOBILE APPLICATION.\n\n` +
            `These terms and conditions of use (“Site Terms”) apply exclusively to your access to, and use of, the Web sites, mobile applications and online services (collectively, “Sites”) of Columbia Brands USA, LLC and its subsidiaries and affiliated companies (collectively, “Columbia”). These Site Terms do not alter in any way the terms or conditions of any other agreement you may have with Columbia for products, services or otherwise. If you are using the Sites on behalf of any entity, you represent and warrant that you are authorized to accept these Site Terms on such entity’s behalf, and that such entity agrees to indemnify you and Columbia for violations of these Site Terms.\n\n` +
            `Columbia reserves the right to change or modify any of the terms and conditions contained in the Site Terms or any policy or guideline of the Sites, at any time and in its sole discretion. Any changes or modification will be effective immediately upon posting of the revisions on the Sites and shall apply to all use of the Sites and all acts or omissions occurring after the effective date of the revised Site Terms. You waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Sites following the posting of changes or modifications will confirm your acceptance of such changes or modifications. Therefore, you should frequently review the Site Terms and applicable policies to understand the terms and conditions that apply to your use of the Sites. If you do not agree to the amended terms, your license to use the Sites will terminate and any further use will be unauthorized, so you must stop using the Sites.\n\n` +
            `If you have any question regarding the use of the Sites, please refer first to the Help Center on the Sites or the Frequently Asked Questions. All other questions or comments about the Sites or their contents should be directed to Columbia via the contact us form.\n\n`}
          </Text>
          <Text fontWeight="bold">1. Electronic Signatures</Text>
          <Text whiteSpace="pre-line">
            {`\nBy using the Site and, where requested, providing information, you agree to generally transact electronically through the Site, including receiving any notices of disclosures required by law. You agree that your electronic signature is the legal equivalent of your manual signature. You further agree that your use of a keyboard, key pad, mouse or other device to type your name or select an item, button, icon or similar act/action, constitutes your signature as if actually signed by you in writing. You also agree that no certification authority or other third party verification is necessary to validate your electronic signature, and the lack of such certification or third party verification will not in any way affect the enforceability of your electronic signature.\n\n` +
              `In order to access, fulfill and retain any of the electronic forms available on the Site, your computer must be equipped and compatible with the most common operating systems and internet browsers. This would include:\n\n` +
              `• Windows, Mac OS X, Linux, Apple iOS, and other major operating systems.\n` +
              `• Microsoft Internet Explorer version 8 and later, Mozilla Firefox – latest version, Safari latest version + tablet versions, Chrome latest desktop version.\n\n` +
              `If you decline to use an electronic signature, you will be able to print forms or other documents and sign them by hand. However, you will also be required to deliver the hand signed documents to the relevant Columbia representative.\n\n` +
              `If you wish to obtain a paper copy of the documents you sign via this site, you will be given the opportunity to download and print those forms once they have been executed.After authorizing the use of your electronic signature, you may still withdraw your consent. To do so, you must contact Columbia for their withdrawal procedures and to understand any consequences or fees which may apply.\n\n`}
          </Text>
          <Text fontWeight="bold">2. Mobile Services and Contact Information</Text>
          <Text whiteSpace="pre-line">{`\nThe Sites may include certain features or services that are available via your mobile phone (the “Mobile Services”), such as the ability to upload content to your mobile phone or request order and shipping status messages or other alerts be sent to your mobile phone. In addition, you are required to provide a phone number in the online account registration process and online order process as part of your contact information, and you have the option, but are not required, to provide your mobile number as your contact number. By using the Mobile Services, or by providing your mobile number as a contact point, you agree that we may communicate with you by SMS, MMS, text message or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us. You understand that your carrier’s normal messaging, data and other rates and fees will apply to these Mobile Services and other communications, and you should check with your carrier to find out what plans are available and how much they cost.\n\n`}</Text>
          <Text fontWeight="bold">3. Copyright and Limited License</Text>
          <Text whiteSpace="pre-line">
            {`\nUnless otherwise indicated in the Sites, the Sites and all content and other materials on the Sites, including, without limitation, the Columbia logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, the “Site Materials”) are the proprietary property of Columbia or its licensors or users and are protected by U.S. and international copyright laws.\n\n` +
              `You are granted a limited, non-sublicensable license to access and use the Sites and electronically copy, (except where prohibited without a license) and print to hard copy portions of the Site Materials for your informational, non-commercial and personal use only. Such license is subject to these Site Terms and does not include: (a) any resale or commercial use of the Sites or the Site Materials therein; (b) the collection and use of any product listings, pictures or descriptions; (c) the distribution, public performance or public display of any Site Materials, (d) modifying or otherwise making any derivative uses of the Sites and the Site Materials, or any portion thereof; (e) use of any data mining, robots or similar data gathering or extraction methods; (f) downloading (other than the page caching) of any portion of the Sites, the Site Materials or any information contained therein, except as expressly permitted on the Sites; or (g) any use of the Sites or the Site Materials other than for their intended purpose. Any use of the Site or the Site Materials other than as specifically authorized herein, without the prior written permission of Columbia, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Site Terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time.\n\n`}
          </Text>
          <Text fontWeight="bold">4. Repeat Infringer Policy</Text>
          <Text whiteSpace="pre-line">
            {`\nIn accordance with the Digital Millennium Copyright Act (DMCA) and other applicable law, Columbia has adopted a policy of terminating, in appropriate circumstances and at Columbia’s sole discretion, subscribers or account holders who are deemed to be repeat infringers. Columbia may also at its sole discretion limit access to the Sites and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.\n\n`}
          </Text>
          <Text fontWeight="bold">5. Copyright Complaints</Text>
          <Text whiteSpace="pre-line">
            {`\nIf you believe that anything on the Site infringes upon any copyright which you own or control, you may file a notification of such infringement with our Designated Agent as set forth below.\n\n` +
              `Name of Agent Designated to Receive Notification of Claimed Infringement: John C. Motley\n\n` +
              `Full Address of Designated Agent to Which Notification Should be Sent: 14375 NW Science Park Drive, Portland, OR 97229\n` +
              `Telephone Number of Designated Agent: 503-985-4000\n` +
              `Facsimile Number of Designated Agent: 503-985-5012\n` +
              `E-Mail Address of Designated Agent: dmca@columbia.com\n\n`}
          </Text>
          <Text whiteSpace="pre-line">
            {`Please see 17 U.S.C. §512(c)(3) for the requirements of a proper notification. Please note that the information provided in your notification, including any personal information contained therein, may be forwarded to the person who has provided the allegedly infringing content, and your sending us such notification constitutes your consent to share this information with the alleged infringer.\n\n` +
            `You should also note that if you make any material misrepresentation in your notification that the material or activity is infringing, you will be liable for all damages, including costs and attorneys’ fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.\n\n` +
            `We may give notice of a claim of copyright infringement to our users by means of a general notice on the Sites, electronic mail to a user’s email address in our records, or by written communication sent by first-class mail to a user’s address in our records.\n\n`}
         </Text>
          <Text fontWeight="bold">6. Trademarks</Text>
          <Text whiteSpace="pre-line">
            {`\n“Columbia,” “Columbia Brands USA, LLC,” “Columbia Interchange System,” “Columbia River Lodge,” “Bugaboo,” “Bugabootoo,” “Cyberpack,” “Delta Hunter,” “Interchange,” “Tough Mother,” “GRT,” “Omni-Dry,” “Omni-Freeze,” “OmniGrip,” “OmniShield,” “OmniShade,” “Omni-Tech,” “PFG,” “PHG,” “Skeeter Beeter,” “Storm Dry,” “Techlite,” “Tested Tough,” “Timberwolf,” and “Titanium,” along with the Columbia diamond, three arrow circle – and the PHG logos, and other product or service names, logos and slogans of Columbia that may appear on the Sites, are trademarks or registered trademarks of Columbia and may not be copied, imitated or used, in whole or in part, without the prior written permission of Columbia or the applicable trademark holder. You may not use any metatags or any other “hidden text” utilizing “Columbia” or any other name, trademark or product or service name of Columbia or its subsidiaries without our prior written permission. In addition, the look and feel of the Sites, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Columbia and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Sites are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us.\n\n`}
          </Text>
          <Text fontWeight="bold">7. Hyperlinks</Text>
          <Text whiteSpace="pre-line">
            {`You are granted a limited, non-exclusive right to create a text hyperlink to the Sites for noncommercial purposes, provided such link does not portray Columbia or any of its products and services in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not contain any adult or illegal material or any material that is offensive, harassing or otherwise objectionable. This limited right may be revoked at any time. You may not use a Columbia logo or other proprietary graphic of Columbia to link to the Sites without the express written permission of Columbia. Further, you may not use, frame or utilize framing techniques to enclose any Columbia trademark, logo or other proprietary information, including the images found at the Sites, the content of any text or the layout/design of any page or form contained on a page on the Sites without Columbia’s express written consent. Except as noted above, you are not conveyed any right or license by implication, estoppel or otherwise in or under any patent, trademark, copyright or proprietary right of Columbia or any third party.\n\n` +
              `Columbia makes no claim or representation regarding, and accepts no responsibility for, the quality, content, nature or reliability of third-party Web sites accessible by hyperlink from the Sites, or Web sites linking to the Sites. Such sites are not under the control of Columbia and Columbia is not responsible for the contents of any linked site or any link contained in a linked site, or any review, changes or updates to such sites. Columbia provides these links to you only as a convenience, and the inclusion of any link does not imply affiliation, endorsement or adoption by Columbia of any site or any information contained therein. When you leave the Sites, you should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from the Sites.\n\n`}
          </Text>
          <Text fontWeight="bold">8. Third Party Content</Text>
          <Text whiteSpace="pre-line">
            {`\nColumbia may provide or allow third party content on the Sites and may provide or allow links to Web pages and content of third parties (collectively the “Third Party Content”) as a service to those interested in this information. Columbia does not monitor or have any control over any Third Party Content or third party Web sites. Columbia does not endorse or adopt any Third Party Content and can make no guarantee as to its accuracy or completeness. Columbia does not represent or warrant the accuracy of any information contained therein and undertakes no responsibility to update or review any Third Party Content. Users use such Third Party Content contained therein at their own risk. Product reviews and comments posted on the Sites are strictly the opinion of the user posting such reviews or comments, and Columbia does not endorse or approve any such reviews or comments.\n\n`}
          </Text>
          <Text fontWeight="bold">9. Advertisements and Promotions; Third-Party Products and Services</Text>
          <Text whiteSpace="pre-line">
            {`\nColumbia may run or allow advertisements and promotions from third parties on the Sites or may otherwise provide or allow information about or links to third-party products or services on the Sites. Your business dealings or correspondence with, or participation in promotions of, such third parties, and any terms, conditions, warranties or representations associated with such dealings or promotions, are solely between you and such third party. Columbia is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or promotions or as the result of the presence of such non-Columbia advertisers or third party information on the Sites. However, the foregoing shall not apply to the extent that any loss or damage is the result of Columbia’s own negligence, fraud, willful injury or willful violation of law.\n\n`}
          </Text>
          <Text fontWeight="bold">10. User Content and Interactive Services or Areas</Text>
          <Text whiteSpace="pre-line">
            {`The Sites may include discussion forums, blogs, product reviews, or other interactive areas or services (“Interactive Areas”) in which you or other users create, post or store any content, messages, reviews, ratings, materials, data, information, text, music, sound, photos, video, graphics, code or other items or materials on the Sites (“User Content”). You are solely responsible for your use of such Interactive Areas and use them at your own risk. By using any Interactive Areas, you agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Sites any of the following:\n\n` +
              `• User Content that is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable;\n` +
              `• User Content that you know or have reason to know is inaccurate, untruthful or misleading;\n` +
              `• User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law;\n` +
              `• User Content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party. By posting any User Content, you represent and warrant that you have the lawful right to distribute and reproduce such User Content;\n` +
              `• User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;\n` +
              `• Unsolicited promotions, political campaigning, advertising or solicitations;\n` +
              `• Private information of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;\n` +
              `• Viruses, corrupted data or other harmful, disruptive or destructive files; and\n` +
              `•User Content that, in the sole judgment of Columbia, is objectionable or which restricts or inhibits any other person from using or enjoying the Interactive Areas or the Sites, or which may expose Columbia or its users to any harm or liability of any type.\n\n` +
              `Columbia takes no responsibility and assumes no liability for any User Content posted, stored or uploaded by you or any third party, or for any loss or damage thereto, nor is Columbia liable for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography or profanity you may encounter. However, the foregoing shall not apply to the extent that any loss or damage is the result of Columbia’s own negligence, fraud, willful injury or willful violation of law.\n\n` +
              `Your use of Interactive Areas is at your own risk. As a provider of interactive services, Columbia is not liable for any statements, representations or User Content provided by its users in any public forum, personal home page or other Interactive Area. Although Columbia has no obligation to screen, edit or monitor any of the User Content posted in any Interactive Area, Columbia reserves the right, and has absolute discretion, to remove, screen or edit any User Content posted or stored on the Sites at any time and for any reason without notice, and you are solely responsible for creating backup copies of and replacing any User Content you post or store on the Sites at your sole cost and expense. Any use of the Interactive Areas or other portions of the Sites in violation of the foregoing violates these Site Terms and may result in, among other things, termination or suspension of your rights to use the Interactive Areas and/or the Sites. In order to cooperate with legitimate governmental requests, subpoenas or court orders, to protect Columbia’s systems and customers, or to ensure the integrity and operation of Columbia’s business and systems, Columbia may access and disclose any information it considers necessary or appropriate, including, without limitation, user profile information (i.e. name, e-mail address, etc.), IP addressing and traffic information, usage history, and posted User Content. Columbia’s right to disclose any such information shall govern over any terms of Columbia’s Privacy Policy.\n\n`}
          </Text>
          <Text fontWeight="bold">11. Rights in User Content and Submissions</Text>
          <Text whiteSpace="pre-line">
            {`\nExcept as otherwise expressly stated by Columbia, Columbia does not claim ownership of any User Content or other materials you post or upload on the Sites or otherwise provide or submit to Columbia. However, unless we indicate otherwise, you grant Columbia and its affiliates a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such User Content throughout the world in any media. Such license shall be limited to use on or in connection with the Sites unless otherwise indicated on the Sites. You grant Columbia and its sublicensees the right to use the name that you submit in connection with such User Content, if they choose. You represent and warrant that (a) you own and control all of the rights to the User Content that you post or you otherwise have the right to post such User Content to the Sites; (b) the User Content is accurate and not misleading; and (c) use and posting of the User Content you supply does not violate these Site Terms and will not violate any rights of or cause injury to any person or entity.\n\n` +
              `Notwithstanding the foregoing, you acknowledge and agree that any suggestions, ideas, comments or other information or materials regarding the Sites, Columbia or Columbia’s products or services that are provided by you, whether by email, posting to the Sites or otherwise, are non-confidential and shall become the sole property of Columbia. Columbia shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these materials for any purpose, commercial or otherwise, without acknowledgment or compensation to you.\n\n`}
          </Text>
          <Text fontWeight="bold">12. Careers</Text>
          <Text whiteSpace="pre-line">
            {`\nThe Site contains pages related to employment opportunities at Columbia and its affiliated companies. The purpose of these pages is to enable you to submit information (“Submissions”) and apply for employment positions within the Columbia and its affiliated companies. Use of this Site does not create an employment relationship between you and any Columbia company. You expressly agree that no such employment relationship will be created without further review of the Submission, an appropriate successful interview process, background and reference checks (where applicable), verification of identity and the legal right to work, and issuance and acceptance of a formal written offer of employment.\n\n` +
              `By making Submissions, you warrant that: (a) you have the right and authority to provide any Submissions; (b) you have only disclosed information that is true, accurate and not misleading (including by omission) and nothing you have submitted is known by you to be false, inaccurate, or misleading; (c) you have a legal right to live and be employed in the jurisdiction in which you are applying for employment. If that legal right is limited in time, you have disclosed when it expires; (d) you are the individual who the Submissions relate to and that you have obtained permission from each person who appears and/or is referred to in your Submissions; (e) your Submissions, or any offer of employment that may result from your Submissions, would not infringe any legal obligation that you may have to any third party, including under laws related to copyright, trademark, patent, trade secret, confidentiality, notice period, restrictive covenant, non-competition or other intellectual property rights, privacy rights, or any other legal or moral rights of any third party; (f) you were not, and will not, be compensated or granted any consideration by any third party for entering your Submission or taking up any offer of employment with a Bank company. and any salary or wages paid to you upon taking up any offer of employment will not be remitted to any third party; and (g) there is no reason that you cannot perform all job duties that you are applying for in a manner that is safe and not injurious to you or any other person, that has not been disclosed fully in your Submission, and you must immediately disclose any such reason should one arise.\n\n` +
              `As an applicant for employment, you may be asked to authorize Columbia, an affiliate, and/or their agents to conduct a background investigation into your qualifications for employment. (Such a background investigation is sometimes called a “Consumer Report” or “Investigative Consumer Report” depending on the conditions under which it is prepared.) Before a background investigation occurs, however, you will receive written disclosure that such an investigation will be made and you will also be asked to authorize the investigation. A background investigation will not be conducted without your authorization.\n\n` +
              `Columbia and its affiliates are committed to providing reasonable accommodation to qualifying candidates with physical and/or mental disabilities and to assisting them with applying for employment and the application process. Reasonable accommodations may be sought by contacting AskHR@Columbia.com.\n\n`}
          </Text>
          <Text fontWeight="bold">13. User Conduct</Text>
          <Text whiteSpace="pre-line">
            {`\nBy using the Sites, you agree not to:\n\n` +
              `• Use the Sites or Interactive Areas in any unlawful manner or in any manner that could damage, disable, overburden or impair the Sites;\n` +
              `• Harvest or collect email addresses or other contact information of other users from the Sites or Interactive Areas by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;\n` +
              `• Use any robot, spider, crawler, scraper or other automated means or interface not provided by us to access the Sites and/or Interactive Areas or to extract data;\n` +
              `• Use automated scripts to collect information from or otherwise interact with the Sites or Interactive Areas;\n` +
              `• Register for more than one user account, register for a user account on behalf of an individual other than yourself, or register for a user account on behalf of any group or entity;\n` +
              `• Impersonate any person or entity or otherwise misrepresent your age or your affiliation with a person or entity;\n` +
              `• Violate any local, state, national or international law;\n` +
              `• Solicit personal information from anyone under 18 or solicit passwords or personally identifying information for commercial or unlawful purposes;\n` +
              `• Use or attempt to use another’s account without authorization from Columbia;\n` +
              `• Attempt to circumvent any content filtering techniques we may employ;\n` +
              `• Attempt to access any service or area of the Sites (including, without limitation, any Interactive Areas) that you are not authorized to access; or\n` +
              `• Engage in any harassing, intimidating, predatory or stalking conduct.\n\n` +
              `Columbia is not responsible or liable for the conduct of, or your interactions with, users of the Sites (whether online or offline), nor is Columbia responsible or liable for any loss, damage, injury, or harm associated therewith. However, the foregoing shall not apply to the extent that any loss or damage is the result of Columbia’s own negligence, fraud, willful injury or willful violation of law.\n\n` +
              `Although Columbia has no obligation to monitor any user conduct on the Sites or in any Interactive Area, Columbia reserves the right, and has absolute discretion, to monitor any user conduct on the Sites at any time and for any reason without notice. Columbia does not approve or endorse any user-posted meetings or events referenced on the Sites, and Columbia recommends exercising caution before contacting or meeting anyone (online or offline) that is unfamiliar. You use of the Sites is at your own risk. Any use of the Sites in violation of the user conduct rules set forth above violates these Site Terms and may result in, among other things, termination or suspension of your rights to use the Interactive Areas and/or the Sites.\n\n`}
          </Text>
          <Text fontWeight="bold">14. Registration Data; Account Security</Text>
          <Text whiteSpace="pre-line">
            {`\nIn consideration of your use of the Sites, and where sections of the Site require registration, you agree to (a) provide accurate, current and complete information about you as may be prompted by any registration forms on the Sites (“Registration Data”); (b) maintain the security of your password and identification and not share such information with any third party; (c) maintain and promptly update the Registration Data, and any other information you provide to Columbia, to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to Columbia, except that this provision shall not apply to the extent that any unauthorized access is the result of Columbia’s own negligence, fraud, willful injury or willful violation of law.\n\n`}
          </Text>
          <Text fontWeight="bold">15. Financial Material Disclosure</Text>
          <Text whiteSpace="pre-line">
            {`\nForward-Looking Statements. The Sites, and any documents issued by Columbia and available through the Sites, may contain statements which constitute forward-looking statements within the meaning of the U.S. Private Securities Litigation Reform Act of 1995. Those statements can be identified by the use of words such as “believe,” “expect,” “plan,” “may,” “will,” “should,” “anticipate” or similar statements or the negative of these words. Forward-looking statements include statements made as to future operations, costs, capital expenditures, cash flow, improvements in infrastructure, distribution and replenishment systems and operating efficiencies, sales and earnings estimates or trends and expansion plans and projections. These forward-looking statements are based on our current expectations. Known and unknown internal and external risks and uncertainties may cause the actual results to be materially different from those expressed in or implied by the forward-looking statements. The information contained in the most recent Columbia Annual Reports to stockholders, including information contained under the section captioned “Management’s Discussion and Analysis,” as well as other information included under the caption “Risk Factors” and/or in other Columbia filings with the Securities and Exchange Commission, identifies important factors that could cause actual results to differ from those contemplated by forward-looking statements. Columbia undertakes no obligation to update forward-looking statements to reflect events or circumstances that occur after the date the statements were made.\n\n` +
              `Press Releases. The information contained within press releases issued by Columbia should not be deemed accurate or current except as of the date the release was posted. Columbia has no intention of updating, and specifically disclaims any duty to update, the information in the press releases. To the extent any information therein is forward-looking, it is intended to fit within the safe harbor for forward-looking statements, and is subject to material risk.\n\n` +
              `Third-Party Financial Information. Columbia may provide links to third-party Web sites or services that contain financial or investment information about Columbia. Access to such Web sites and the information contained therein is provided as service to those interested in the information. Columbia neither regularly monitors nor has control over the content of third parties’ statements or Web sites. Accordingly, Columbia does not endorse or adopt these Web sites or any information contained therein, including, without limitation, analyst’s reports and stock quotes. Columbia makes no representations or warranties whatsoever regarding the accuracy or completeness of the content, information, or opinions of third-party Web sites or other third-party information that is identified on the Site. Users visit these Web sites and use the information contained therein at their own risk.\n\n`}
          </Text>
          <Text fontWeight="bold">16. Indemnification</Text>
          <Text whiteSpace="pre-line">
            {`\nTo the extent permitted by law, you agree to defend, indemnify and hold harmless Columbia, its independent contractors, service providers and consultants, and their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys’ fees) arising out of or related to any User Content you post, store or otherwise transmit on or through the Sites or your use of the Interactive Areas, including without limitation any actual or threatened suit, demand or claim made against Columbia and/or its independent contractors, service providers, employees, directors or consultants, arising out of or relating to the User Content, your conduct, your violation of these Site Terms or your violation of the rights of any third party.\n\n`}
          </Text>
          <Text fontWeight="bold">17. Disclaimer</Text>
          <Text whiteSpace="pre-line">
            {`\nEXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY COLUMBIA, THE SITES, THE SITE MATERIALS CONTAINED THEREIN (INCLUDING ALL USER CONTENT) AND THE SERVICES PROVIDED ON OR IN CONNECTION THEREWITH (THE “SERVICES”) ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. COLUMBIA DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE INFORMATION, USER CONTENT AND SITE MATERIALS IN THE SITES. COLUMBIA DOES NOT REPRESENT OR WARRANT THAT THE SITES, THE SITE MATERIALS OR THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. COLUMBIA DOES NOT REPRESENT OR WARRANT THAT THE SITE OR ITS SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. COLUMBIA IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SITES OR THE SERVICE, AND YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. PRODUCT REVIEWS AND COMMENTS ARE STRICTLY THE OPINION OF THE USER POSTING SUCH REVIEWS OR COMMENTS, AND COLUMBIA DOES NOT ENDORSE OR APPROVE ANY SUCH REVIEWS OR COMMENTS.\n\n` +
              `COLUMBIA IS NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING TO PRICING, TEXT OR PHOTOGRAPHY. WHILE COLUMBIA ATTEMPTS TO MAKE YOUR ACCESS AND USE OF THE SITES AND THE SERVICES SAFE, COLUMBIA CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITES OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD.\n\n` +
              `Columbia reserves the right to change any and all content contained in the Sites and any Services offered through the Sites at any time without notice. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by Columbia.\n\n`}
          </Text>
          <Text fontWeight="bold">18. Limitation of Liability</Text>
          <Text whiteSpace="pre-line">
            {`\nIN NO EVENT SHALL COLUMBIA, ITS DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SITES, THE SERVICES, THE USER CONTENT OR THE SITE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SITES, INCLUDING WITHOUT LIMITATION ANY DAMAGES CASED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM COLUMBIA, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO COLUMBIA’S RECORDS, PROGRAMS OR SERVICES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF COLUMBIA, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SITES EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO COLUMBIA FOR ACCESS TO OR USE OF THE SITES.\n\n` +
              `HOWEVER, THE FOREGOING LIMITATION OF LIABILITY PROVISIONS SHALL NOT APPLY TO THE EXTENT THAT ANY CLAIMS, DAMAGES, COSTS OR EXPENSES ARE THE RESULT OF COLUMBIA’S OWN NEGLIGENCE , FRAUD, WILLFUL INJURY OR WILLFUL VIOLATION OF LAW.\n\n`}
          </Text>
          <Text fontWeight="bold">19. Applicable Law and Venue</Text>
          <Text whiteSpace="pre-line">
            {`\nThese Site Terms and your use of the Sites shall be governed by and construed in accordance with the laws of the State of Oregon, applicable to agreements made and to be entirely performed within the State of Oregon, without resort to its conflict of law provisions. You agree that any action not filed in arbitration pursuant to these SiteTerms shall be filed only in the state and federal courts located in Multnomah County, Oregon and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any such suit, action or proceeding.\n\n`}
          </Text>
          <Text fontWeight="bold">20. Dispute Resolution</Text>
          <Text whiteSpace="pre-line">
            {`\nANY DISPUTE OR CLAIM BETWEEN YOU AND COLUMBIA, its agents, employees, successors, assigns, and direct and indirect subsidiaries RELATING IN ANY WAY TO OR ARISING IN ANY WAY FROM YOUR USE OF THE SITES, OR THESE TERMS OF USE (INCLUDING THEIR INTERPRETATION, VALIDITY, TERMINATION OR BREACH) WILL BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement. The arbitrator shall have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision including any unconscionability challenge or any other challenge that the arbitration provision or these Terms of Use are void, voidable or otherwise invalid.\n\n` +
              `THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THE TERMS OF THESE TERMS OF USE AS A COURT WOULD.\n\n` +
              `To begin an arbitration proceeding, you may send a letter requesting arbitration and describing your claim to our registered agent or to Columbia at Columbia Brands USA, LLC, Attn: General Counsel, 14375 NW Science Park Drive, Portland, Oregon 97229. You agree that the arbitration will be conducted by and in accordance with the rules of either the American Arbitration Association (“AAA”), adr.org, 1.800.778.7879, or the Judicial Arbitration and Mediation Services (“JAMS”), jamsadr.com, 1.800.352.5267. Such arbitration shall proceed either in the county in which you reside or Multnomah County, Oregon. You can contact AAA or JAMS to find out more information on how to commence an arbitration proceeding. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s or JAMS’ applicable rules. Columbia will reimburse those fees for claims totaling less than $10,000. Likewise, Columbia will not seek attorneys’ fees and costs in arbitration. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.\n\n` +
              `WE EACH AGREE TO BRING ANY DISPUTE IN ARBITRATION ON AN INDIVIDUAL BASIS ONLY, AND NOT ON A CLASS, CONSOLIDATED, REPRESENTATIVE OR COLLECTIVE ACTION BASIS. IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN ARBITRATION, WE EACH WAIVE ANY RIGHT TO A JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION AGAINST THE OTHER. IN ADDITION, WE BOTH AGREE THAT WE EACH MAY BRING SUIT IN COURT TO ENJOIN INFRINGEMENT OR OTHER MISUSE OF INTELLECTUAL PROPERTY RIGHTS.\n\n` +
              `THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA OR JAMS RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU OR COLUMBIA WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION.\n\n`}
          </Text>
          <Text fontWeight="bold">21. Termination</Text>
          <Text whiteSpace="pre-line">
            {`\nNotwithstanding any of these Site Terms, Columbia reserves the right, without notice and in its sole discretion, to terminate your license to use the Sites, and to block or prevent future your access to and use of the Sites.\n\n`}
          </Text>
          <Text fontWeight="bold">22. Severability</Text>
          <Text whiteSpace="pre-line">
            {`\nIf any provision of these Site Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Site Terms and shall not affect the validity and enforceability of any remaining provisions.\n\n`}
          </Text>
          <Text fontWeight="bold">23. Questions & Contact Information</Text>
          <Text whiteSpace="pre-line">
            {`\nQuestions or comments about the Sites or these Site Terms may be directed to Columbia via the contact us form or by calling us at (800) 622-6953.\n\n`}
          </Text>
        </Box>
      </Stack>
    </HomeLayout>
  );
}

export default TermsOfUse;
