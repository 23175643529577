import React from 'react';
import {
  Text,
  Image,
  Stack,
  Box,
  VStack,
  Container,
  Flex,
  useBreakpointValue,
  Heading,
} from '@chakra-ui/react';

import HomeLayout from '../../components/layouts/homelayout';
import Seo from '../../components/sections/seo';
import HeroWithRightMedia from '../../components/sections/herowithrightmedia';
import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';

import InclusionDiversityHero from '../../components/ui/ourcompany/S18-Montrail-TK-1187-copy-crop1651x903.png'
import InclusionDiversitySelfie from '../../components/ui/ourcompany/S19_CAS_BR_OR_0068-copy.jpeg'
import GertImg from '../../components/ui/ourcompany/gert_boyle.jpeg'

export default function StrategyMissionValues() {
  return (
    <HomeLayout align="left">
      <Seo
        title="Diversity, Equity, & Inclusion"
        description="We connect active people with their passions across race, ethnicity, gender, ability, religion and sexual orientation."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Stack w="full" className='page-banner'></Stack>
      <Flex
        w={'full'}
        h={'60vh'}
        backgroundImage={InclusionDiversityHero}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        id="strategyMissionValuesHeroImg"
      >
        <VStack
          w={'full'}
          justify={'flex-end'}
          align={'left'}
          pb={useBreakpointValue({ base: 4, md: 12 })}
          bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
        >
          <Container maxW={'6xl'}>
            <Heading
              as={'h1'}
              color={'white'}>
              The Outdoors is for Everyone
            </Heading>
          </Container>
        </VStack>
      </Flex>
      <Container maxW={'6xl'}>
      <HeroWithRightMedia
        title={
          <Box>
            <Text fontWeight={'bold'} mt="10px">
              INCLUSION, DIVERSITY & EQUITY
            </Text>
          </Box>
        }
        subTitle={
          <Text mb="4">
            The outdoors is diverse. In nature, different colors, shapes and
            species thrive due to their diversity. Diverse perspectives lead to
            creativity and innovation. Equity leads to empowerment. Inclusion
            leads to teamwork. We've grown from a family business to a global
            company because we understand what it takes to flourish. Since 1938,
            we have been making clothing and footwear so that more people can
            enjoy the outdoors. We connect active people with their passions
            across race, ethnicity, gender, ability, religion and sexual
            orientation. Doing the right thing for our consumers, customers,
            employees, the company and our communities is one of our core
            values. We are dedicated to creating a company that is diverse,
            equitable and inclusive because one thing that we can all agree on
            is the outdoors is for all of us.
          </Text>
        }
        media={<Image borderRadius={10} src={InclusionDiversitySelfie} />}
      />
      <HeroWithLeftMedia
        title={
          <Box>
            <Text fontWeight={'bold'} mt="10px">
              OUR HISTORY INFORMS OUR PRESENT
            </Text>
          </Box>
        }
        subTitle={
          <Text mb="4">
            Columbia Sportswear Company was founded by immigrants fleeing Nazi
            Germany. Their daughter, Gert Boyle, held every position, from
            seamstress of the first fishing vest to President and Chair of the
            Board. Ma Boyle was a pioneer as a woman in the outdoor industry.
            Her legacy continues to inspire us now that we are a global company
            with more than 7,000 employees all over the world.
          </Text>
        }
        media={<Image borderRadius={10} src={GertImg} />}
      />
      </Container>
    </HomeLayout>
  );
}
