import React from 'react';
import { Box, Heading, Container, Text } from '@chakra-ui/react';

import CarouselSimple from '../../components/sections/carouselsimple';
import HomeLayout from '../../components/layouts/homelayout';
import Seo from '../../components/sections/seo'
import Heromm from '../../components/sections/heromm';
import SimpleFourColumns from '../../components/sections/simplefourcolumns';
import DynamicTabs from '../../components/sections/dynamictabs';
import StoryGrid from '../../components/sections/storygrid';

// Set up the multi-media hero component
import heroVideo from '../../components/ui/globalcareers/csc-com_hero.mp4';
import heroImg from '../../components/ui/globalcareers/hq_hero.jpg';

// Fill the carousel
import facilitiesImg from '../../components/ui/globalcareers/carousel/facilities_administration.jpg';
import hrLegalImg from '../../components/ui/globalcareers/carousel/hr_legal_finance.jpg';
import innovationImg from '../../components/ui/globalcareers/carousel/innovation.jpg';
import manufacturingImg from '../../components/ui/globalcareers/carousel/manufacturing.jpg';
import productDesignImg from '../../components/ui/globalcareers/carousel/product_design.jpg';
import salesImg from '../../components/ui/globalcareers/carousel/sales.jpg';
import supplyChainImg from '../../components/ui/globalcareers/carousel/supply_chain.jpg';
import technologyImg from '../../components/ui/globalcareers/carousel/technology.jpg';
import retailImg from '../../components/ui/globalcareers/carousel/retail.jpg';
import maktImg from '../../components/ui/globalcareers/carousel/marketing.jpg';
import custCareImg from '../../components/ui/globalcareers/carousel/customer_care.jpg';
import distImg from '../../components/ui/globalcareers/carousel/distribution.jpg';

// Tab imagery
import controlBkgImage from '../../components/ui/globalcareers/regions/csc_hq.jpg';
import naTopImage from '../../components/ui/globalcareers/regions/northamerica/lillehammer.jpg';
import naLeftImage from '../../components/ui/globalcareers/regions/northamerica/retail_na.jpg';
import naRightImage from '../../components/ui/globalcareers/regions/northamerica/distribution_na.jpg';

import euTopImage from '../../components/ui/globalcareers/regions/europe/eu_hq.jpg';
import euLeftImage from '../../components/ui/globalcareers/regions/europe/eu_retail.jpg';
import euRightImage from '../../components/ui/globalcareers/regions/europe/eu_dc.jpg';

import asTopImage from '../../components/ui/globalcareers/regions/asia/as_office.jpg';
import asLeftImage from '../../components/ui/globalcareers/regions/asia/as_china_retail.jpg';
import asRightImage from '../../components/ui/globalcareers/regions/asia/as_garden.jpg';

// Story Grid
import wellbeingImage from '../../components/ui/globalcareers/evp/wellbeing.jpg';
import corpRespImage from '../../components/ui/globalcareers/evp/corp_resp.jpg';
import historyImage from '../../components/ui/ourcompany/gert_boyle.jpeg';
import deiImage from '../../components/ui/globalcareers/evp/dei.png';

function Careers() {

  const passions = [{
    id: 1,
    heading: 'Technology',
    image: `${technologyImg}`,
    url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
    id: 2,
    heading: 'Ecommerce & Customer Care',
    image: `${custCareImg}`,
    url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
    id: 3,
    heading: "Retail",
    image: `${retailImg}`,
    url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
    id: 4,
    heading: 'Distribution',
    image: `${distImg}`,
    url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
    id: 5,
    heading: 'Marketing',
    image: `${maktImg}`,
    url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 6,
  heading: 'Supply Chain',
  image: `${supplyChainImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 7,
  heading: 'Facilities & Administration',
  image: `${facilitiesImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 8,
  heading: 'HR, Legal, & Finance',
  image: `${hrLegalImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 9,
  heading: 'Innovation',
  image: `${innovationImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 10,
  heading: 'Manufacturing',
  image: `${manufacturingImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 11,
  heading: 'Product Design & Development',
  image: `${productDesignImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
{
  id: 12,
  heading: 'Sales',
  image: `${salesImg}`,
  url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom'
},
];

  return (
    <HomeLayout>
      <Seo
        title="Global Careers"
        description="Find your next adventure with a job at Columbia Sportswear Company."
        links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
        <Box className='page-banner' w="full">
          <Heromm
            title={['Your journey ', <br />, 'starts here.']}
            backgroundImage={heroImg}
            backgroundVideo={heroVideo}
            callToAction={<>
            <Box
                    className='video-feature__button-group'
                >
                    <Text
                        mb={'var(--space-xs)'}
                        fontWeight={'600'}
                        >
                            Search jobs at CSC
                    </Text>
                    <Box
                        className='cluster'
                    >
                        <Box 
                            as='a'
                            className='btn btn-black btn-normal pill-border'
                            href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/retail?source_id=csccom'
                            target='_blank'
                        >
                            Retail
                        </Box>
                        <Box 
                            as='a'
                            className='btn btn-black btn-normal pill-border'
                            href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/DistributionCenters?source_id=csccom'
                            target='_blank'
                        >
                            Distribution
                        </Box>
                        <Box 
                            as='a'
                            className='btn btn-black btn-normal pill-border'
                            href='https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?jobFamilyGroup=92152ee7342d01050d53d7cf0e01330a&jobFamilyGroup=92152ee7342d01845376d8cf0e01370a&jobFamilyGroup=92152ee7342d016285b8dacf0e013f0a&jobFamilyGroup=92152ee7342d01f5f214dccf0e01430a&jobFamilyGroup=92152ee7342d019e308fdccf0e01450a&jobFamilyGroup=92152ee7342d0108d7d3ddcf0e014b0a&jobFamilyGroup=92152ee7342d0127134edecf0e014d0a&jobFamilyGroup=92152ee7342d01a480e7decf0e014f0a&jobFamilyGroup=db9a16dd9a92100151f7255ff8870000&jobFamilyGroup=db9a16dd9a92100151f78ee5d4de0000&jobFamilyGroup=8e1e6e59612010015205a05d908e0000&jobFamilyGroup=8e1e6e59612010015205c40795a50000&jobFamilyGroup=8e1e6e59612010015205e18cb6e40000&jobFamilyGroup=8e1e6e59612010015205f13c4cb10000&jobFamilyGroup=36a7d33c612d10015205b288928d0000&jobFamilyGroup=36a7d33c612d10015205f7fa50e80000&jobFamilyGroup=19fb45e53634100152656a3077b60000&jobFamilyGroup=19fb45e5363410015265793514620000&source_id=csccom'
                            target='_blank'
                        >
                            Corporate
                        </Box>
                    </Box>
                    <Box>
                        <Text
                            fontSize={'var(--step--2)'}
                            mt={'var(--space-l)'}
                            fontWeight={'600'}>
                            Current employee? <Box as='a' target='_blank' href='https://wd5.myworkday.com/columbiasportswearcompany/d/inst/13102!CK5mGhIKBggDEMenAhIICgYI1A0Q7wE~*YPh8zDET8fI~/cacheable-task/2998$46522.htmld#backheader=true' className='btn btn-darkgray btn-thin pill-border' ml={'var(--space-3xs)'}>Search jobs</Box>
                        </Text>
                    </Box>
                </Box>
            </>}
            />
        </Box>

        <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-2xl)'}}>
        <Box as='section'>
          <Heading
            className='section-heading'
            fontSize={'var(--step-3)'}
            textTransform={'none'}
            mb={'var(--space-l)'}
          >
                Choose your destination
          </Heading>
          <CarouselSimple cards={passions}></CarouselSimple>
        </Box>
        <Box as='section'
          width={{ base: '100vw', md: 'auto' }}
          marginLeft={{base: 'calc(50% - 50vw)', md: 0 }}
            >
          <Box
            className='life-section-heading'
            background={'#eaebed'}
            maxW={'60ch'}
            padding={'var(--space-m)'}
            pos={'absolute'}
            minH={'50vh'}>
            <Heading
              className='section-heading'
              fontSize={'var(--step-3)'}
              textTransform={'none'}
              mb={'var(--space-3xs)'}
            >
                  Life at CSC
            </Heading>
            <Text
              fontSize={'var(--step-0)'}
            >
              At Columbia Sportswear Company, we have a positive impact on the people we reach, the places we touch, and the products we make.
            </Text>
          </Box>
          
          <StoryGrid 
            featureImage={historyImage}
            tallImage={corpRespImage}
            wellbeingImage={wellbeingImage}
            deiImage={deiImage}
          />
        </Box>
        <Box as='section'>
          <Heading
              className='section-heading'
              fontSize={'var(--step-3)'}
              textTransform={'none'}
              mb={{ base: 'var(--space-l)', md: 0 }}
            >
                  Our Brands
            </Heading>
            <SimpleFourColumns />
        </Box>
        <Box as='section'
          className='full-bleed'>
          <DynamicTabs 
            controlBkgImage={controlBkgImage}
            naTopImage={naTopImage}
            naLeftImage={naLeftImage}
            naRightImage={naRightImage}

            euTopImage={euTopImage}
            euLeftImage={euLeftImage}
            euRightImage={euRightImage}

            asTopImage={asTopImage}
            asLeftImage={asLeftImage}
            asRightImage={asRightImage}
          />
        </Box>
      </Container>
    </HomeLayout>
  );
}

export default Careers;
