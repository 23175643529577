import React from 'react';
import { Button, Image, LinkBox, LinkOverlay, VStack, Box, Icon, Container, Heading } from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Seo from '../../../components/sections/seo';

import Recos from '../../../components/sections/recos';
import HeroWithStackedMedia from '../../../components/sections/herowithstackedmedia';
import HeadingWithText from '../../../components/sections/headingwithtext'

import sorCrosswalk from '../../../components/ui/sorel-crosswalk.jpg';
import sorJetSki from '../../../components/ui/sorel-jet-ski.jpg';

export default function SorelTabPanel() {
  return (
    <Box>
      <Seo
        title="Careers at SOREL"
        description="Find your next career at SOREL."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='6xl' my={14} className='flow' sx={{'--flow-space': 'space.12'}}>
      <HeroWithStackedMedia
        title={<Heading as='h1'>CREATING POWERFUL FOOTWEAR FOR UNSTOPPABLE INDIVIDUALS</Heading>}
        subTitle="Here at SOREL, we believe you are your most powerful tool and the determination you find within is an unstoppable force. SOREL challenges the status quo by creating unexpected footwear that pushes the boundaries of function-first fashion. We stay in motion so you can push yourself forward."
        media={<ReactPlayer url="https://www.youtube.com/embed/QcGAjwNkzMc?autoplay=0&rel=0&enablejsapi=1&wmode=opaque" />}/>
      <HeroWithStackedMedia
        title="OUR CORE BELIEFS"
        subTitle="At SOREL, we aspire to seize opportunities and make bold statements. We’re not satisfied unless we’re exhausting all possibilities. Our passion for improvement comes with a sense of urgency, we are relentless in finding the best solutions possible. We question, explore, disrupt and lead, all while looking for ways to be better. SOREL acts with purpose while making sure every decision is made with absolute intention."
        media={<Image src={sorCrosswalk}/>}
      />
      <HeroWithStackedMedia
        title="SOREL EVOLUTION"
        subTitle="Founded in 1962, SOREL combines expert craftsmanship and of-the-moment design to create all-season footwear. What began decades ago with premium boots, crafted with an unparalleled balance of construction and protection, is now a brand dedicated to both functionality and fashion."
        media={<Image src={sorJetSki}/>}
      />
      <HeadingWithText
        title="WHY WORK AT SOREL"
        subTitle={`Being part of the SOREL tribe is for the few that dare to draw outside the lines. It’s a culture comprised of individuals audaciously pushing forward as one. New ideas fuel us as we explore the unknown, while never being afraid to charge the edge. SOREL is an institution of creators, doers and leaders who bring the spirit of the boot in everything we make.\n\nCentered in the heart of downtown Portland, SOREL headquarters is walking distance from a range of theatres, museums, parks, farmers markets and amazing food. Pioneer Courthouse Square, Director Park, Portland Art Museum, Arlene Schnitzer Hall and Waterfront Park are just a few amenities Portland has to offer.`}
        justify="center"
        align="center"
        my={4}
      />
      <VStack justify="center" margin="10px">
        <LinkBox as="a">
          <LinkOverlay href="https://columbiasportswearcompany.wd5.myworkdayjobs.com/SOREL_Careers?source_id=csccom" isExternal>
            <Button bg="black" color="white" size="lg" borderRadius={0}>VIEW JOBS AT SOREL<Icon color="white" w={5} h={5} as={ExternalLinkIcon} pl="4px"/></Button>
          </LinkOverlay>
        </LinkBox>
        <Recos siteId="bcpx-Sorel_US" title="Our Best Sellers"/>
        <LinkBox as="a">
          <LinkOverlay href="https://www.sorel.com" isExternal>
            <Button bg="black" color="white" size="lg" borderRadius={0} rightIcon={<ExternalLinkIcon />}>Visit Sorel.com</Button>
          </LinkOverlay>
        </LinkBox>
      </VStack>
      </Container>
    </Box>
  )
}
