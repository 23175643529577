import { Box, Image, Text } from '@chakra-ui/react';

export default function BlogPost({ title, postIntro, featuredImage, featuredAlt, date, postContent }) {
  return (
    <Box as="article">
    <Box
        as='header'
        className='page-banner'
        maxW={'1440px'}
        marginX={'auto'}
        pt={12}
    >
        <Box
            className='article-author'
            textAlign={'center'}
            maxW={'80ch'}
            marginX={'auto'}
            mb={8}
        >
            <h1>{ title }</h1>
            { date ? <p>{ date }</p> : null }
            {postIntro ? (<Text fontStyle={'italic'} my={12} fontSize={'var(--step-1)'}>{ postIntro }</Text>) : ( null )}
        </Box>
        <Box 
            className='article-hero'
            //p={{ base: 0, md: 12 }}
            >
            <Image 
                src={ featuredImage }
                alt={ featuredAlt }
            />
        </Box>
    </Box>
    <Box
        className='article-content flow font-0'
        maxW={'80ch'}
        marginX={'auto'}
        paddingX={'clamp(1.09rem, 1rem + 0.47vw, 1.33rem)'}
        sx={{'--flow-lh': '1.6'}}
        marginY={'12'}
    >
        { postContent }

    </Box>
</Box>

  );
}
